@charset "UTF-8";/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --vz-blue: #3577f1;
    --vz-indigo: #405189;
    --vz-purple: #6559cc;
    --vz-pink: #f672a7;
    --vz-red: #f06548;
    --vz-orange: #f1963b;
    --vz-yellow: #f7b84b;
    --vz-green: #0ab39c;
    --vz-teal: #02a8b5;
    --vz-cyan: #299cdb;
    --vz-white: #fff;
    --vz-gray: #878a99;
    --vz-gray-dark: #343a40;
    --vz-gray-100: #f3f6f9;
    --vz-gray-200: #eff2f7;
    --vz-gray-300: #e9ebec;
    --vz-gray-400: #ced4da;
    --vz-gray-500: #adb5bd;
    --vz-gray-600: #878a99;
    --vz-gray-700: #495057;
    --vz-gray-800: #343a40;
    --vz-gray-900: #212529;
    --vz-primary: #405189;
    --vz-secondary: #3577f1;
    --vz-success: #0ab39c;
    --vz-info: #299cdb;
    --vz-warning: #f7b84b;
    --vz-danger: #f06548;
    --vz-danger-bg-subtle: #fde8e4;
    --vz-primary-bg-subtle: #e2e5ed;
    --vz-secondary-bg-subtle: #e1ebfd;
    --vz-success-bg-subtle: #daf4f0;
    --vz-info-bg-subtle: #dff0fa;
    --vz-warning-bg-subtle: #fef4e4;
    --vz-light-bg-subtle: #f9fbfc;
    --vz-dark-bg-subtle: #e9ebec;
    --vz-light: #f3f6f9;
    --vz-dark: #212529;
    --vz-primary-rgb: 64,81,137;
    --vz-secondary-rgb: 53,119,241;
    --vz-success-rgb: 10,179,156;
    --vz-info-rgb: 41,156,219;
    --vz-warning-rgb: 247,184,75;
    --vz-danger-rgb: 240,101,72;
    --vz-light-rgb: 243,246,249;
    --vz-dark-rgb: 33,37,41;
    --vz-white-rgb: 255,255,255;
    --vz-black-rgb: 0,0,0;
    --vz-body-color-rgb: 33,37,41;
    --vz-body-bg-rgb: 243,243,249;
    --vz-font-sans-serif: "Poppins",sans-serif;
    --vz-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --vz-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --vz-body-font-family: var(--vz-font-sans-serif);
    --vz-body-font-size: 0.8125rem;
    --vz-body-font-weight: 400;
    --vz-body-line-height: 1.5;
    --vz-body-color: #212529;
    --vz-body-bg: #f3f3f9;
    --vz-border-width: 1px;
    --vz-border-style: solid;
    --vz-border-color: var(--vz-border-color);
    --vz-border-color-translucent: rgba(0, 0, 0, 0.175);
    --vz-border-radius: 0.25rem;
    --vz-border-radius-sm: 0.2rem;
    --vz-border-radius-lg: 0.3rem;
    --vz-border-radius-xl: 1rem;
    --vz-border-radius-2xl: 2rem;
    --vz-border-radius-pill: 50rem;
    --vz-link-color: var(--vz-link-color);
    --vz-link-hover-color: var(--vz-link-hover-color);
    --vz-code-color: #f672a7;
    --vz-highlight-bg: #fcf8e3
}

*,::after,::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--vz-body-font-family);
    font-size: var(--vz-body-font-size);
    font-weight: var(--vz-body-font-weight);
    line-height: var(--vz-body-line-height);
    color: var(--vz-body-color);
    text-align: var(--vz-body-text-align);
    background-color: var(--vz-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: .25
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h1,h1 {
    font-size: calc(1.328125rem + .9375vw)
}

@media (min-width: 1200px) {
    .h1,h1 {
        font-size:2.03125rem
    }
}

.h2,h2 {
    font-size: calc(1.2875rem + .45vw)
}

@media (min-width: 1200px) {
    .h2,h2 {
        font-size:1.625rem
    }
}

.h3,h3 {
    font-size: calc(1.2671875rem + .20625vw)
}

@media (min-width: 1200px) {
    .h3,h3 {
        font-size:1.421875rem
    }
}

.h4,h4 {
    font-size: 1.21875rem
}

.h5,h5 {
    font-size: 1.015625rem
}

.h6,h6 {
    font-size: .8125rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,ul {
    padding-left: 2rem
}

dl,ol,ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,ol ul,ul ol,ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 600
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,strong {
    font-weight: bolder
}

.small,small {
    font-size: .875em
}

.mark,mark {
    padding: .2em;
    background-color: var(--vz-highlight-bg)
}

sub,sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: var(--vz-link-color);
    text-decoration: underline
}

a:hover {
    color: var(--vz-link-hover-color)
}

a:not([href]):not([class]),a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,kbd,pre,samp {
    font-family: var(--vz-font-monospace);
    font-size: 1em
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875em;
    color: var(--vz-code-color);
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: .875em;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
}

figure {
    margin: 0 0 1rem
}

img,svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #878a99;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,td,tfoot,th,thead,tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,input,optgroup,select,textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none!important
}

[type=button],[type=reset],[type=submit],button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width: 1200px) {
    legend {
        font-size:1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-fields-wrapper,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-text,::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

::file-selector-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none!important
}

.lead {
    font-size: 1.015625rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-1 {
        font-size:5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-2 {
        font-size:4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-3 {
        font-size:4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-4 {
        font-size:3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-5 {
        font-size:3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width: 1200px) {
    .display-6 {
        font-size:2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: .89375rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #878a99
}

.blockquote-footer::before {
    content: "— "
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: var(--vz-body-bg);
    border: 1px solid var(--vz-border-color);
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: #878a99
}

.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
    --vz-gutter-x: 1.5rem;
    --vz-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--vz-gutter-x) * .5);
    padding-left: calc(var(--vz-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container,.container-sm {
        max-width:540px
    }
}

@media (min-width: 768px) {
    .container,.container-md,.container-sm {
        max-width:720px
    }
}

@media (min-width: 992px) {
    .container,.container-lg,.container-md,.container-sm {
        max-width:960px
    }
}

@media (min-width: 1200px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl {
        max-width:1140px
    }
}

@media (min-width: 1400px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
        max-width:1320px
    }
}

.row {
    --vz-gutter-x: 1.5rem;
    --vz-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--vz-gutter-y));
    margin-right: calc(-.5 * var(--vz-gutter-x));
    margin-left: calc(-.5 * var(--vz-gutter-x))
}

.row>* {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--vz-gutter-x) * .5);
    padding-left: calc(var(--vz-gutter-x) * .5);
    margin-top: var(--vz-gutter-y)
}

.col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%
}

.row-cols-auto>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

.g-0,.gx-0 {
    --vz-gutter-x: 0
}

.g-0,.gy-0 {
    --vz-gutter-y: 0
}

.g-1,.gx-1 {
    --vz-gutter-x: 0.25rem
}

.g-1,.gy-1 {
    --vz-gutter-y: 0.25rem
}

.g-2,.gx-2 {
    --vz-gutter-x: 0.5rem
}

.g-2,.gy-2 {
    --vz-gutter-y: 0.5rem
}

.g-3,.gx-3 {
    --vz-gutter-x: 1rem
}

.g-3,.gy-3 {
    --vz-gutter-y: 1rem
}

.g-4,.gx-4 {
    --vz-gutter-x: 1.5rem
}

.g-4,.gy-4 {
    --vz-gutter-y: 1.5rem
}

.g-5,.gx-5 {
    --vz-gutter-x: 3rem
}

.g-5,.gy-5 {
    --vz-gutter-y: 3rem
}

@media (min-width: 576px) {
    .col-sm {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }

    .g-sm-0,.gx-sm-0 {
        --vz-gutter-x: 0
    }

    .g-sm-0,.gy-sm-0 {
        --vz-gutter-y: 0
    }

    .g-sm-1,.gx-sm-1 {
        --vz-gutter-x: 0.25rem
    }

    .g-sm-1,.gy-sm-1 {
        --vz-gutter-y: 0.25rem
    }

    .g-sm-2,.gx-sm-2 {
        --vz-gutter-x: 0.5rem
    }

    .g-sm-2,.gy-sm-2 {
        --vz-gutter-y: 0.5rem
    }

    .g-sm-3,.gx-sm-3 {
        --vz-gutter-x: 1rem
    }

    .g-sm-3,.gy-sm-3 {
        --vz-gutter-y: 1rem
    }

    .g-sm-4,.gx-sm-4 {
        --vz-gutter-x: 1.5rem
    }

    .g-sm-4,.gy-sm-4 {
        --vz-gutter-y: 1.5rem
    }

    .g-sm-5,.gx-sm-5 {
        --vz-gutter-x: 3rem
    }

    .g-sm-5,.gy-sm-5 {
        --vz-gutter-y: 3rem
    }
}

@media (min-width: 768px) {
    .col-md {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,.gx-md-0 {
        --vz-gutter-x: 0
    }

    .g-md-0,.gy-md-0 {
        --vz-gutter-y: 0
    }

    .g-md-1,.gx-md-1 {
        --vz-gutter-x: 0.25rem
    }

    .g-md-1,.gy-md-1 {
        --vz-gutter-y: 0.25rem
    }

    .g-md-2,.gx-md-2 {
        --vz-gutter-x: 0.5rem
    }

    .g-md-2,.gy-md-2 {
        --vz-gutter-y: 0.5rem
    }

    .g-md-3,.gx-md-3 {
        --vz-gutter-x: 1rem
    }

    .g-md-3,.gy-md-3 {
        --vz-gutter-y: 1rem
    }

    .g-md-4,.gx-md-4 {
        --vz-gutter-x: 1.5rem
    }

    .g-md-4,.gy-md-4 {
        --vz-gutter-y: 1.5rem
    }

    .g-md-5,.gx-md-5 {
        --vz-gutter-x: 3rem
    }

    .g-md-5,.gy-md-5 {
        --vz-gutter-y: 3rem
    }
}

@media (min-width: 992px) {
    .col-lg {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,.gx-lg-0 {
        --vz-gutter-x: 0
    }

    .g-lg-0,.gy-lg-0 {
        --vz-gutter-y: 0
    }

    .g-lg-1,.gx-lg-1 {
        --vz-gutter-x: 0.25rem
    }

    .g-lg-1,.gy-lg-1 {
        --vz-gutter-y: 0.25rem
    }

    .g-lg-2,.gx-lg-2 {
        --vz-gutter-x: 0.5rem
    }

    .g-lg-2,.gy-lg-2 {
        --vz-gutter-y: 0.5rem
    }

    .g-lg-3,.gx-lg-3 {
        --vz-gutter-x: 1rem
    }

    .g-lg-3,.gy-lg-3 {
        --vz-gutter-y: 1rem
    }

    .g-lg-4,.gx-lg-4 {
        --vz-gutter-x: 1.5rem
    }

    .g-lg-4,.gy-lg-4 {
        --vz-gutter-y: 1.5rem
    }

    .g-lg-5,.gx-lg-5 {
        --vz-gutter-x: 3rem
    }

    .g-lg-5,.gy-lg-5 {
        --vz-gutter-y: 3rem
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,.gx-xl-0 {
        --vz-gutter-x: 0
    }

    .g-xl-0,.gy-xl-0 {
        --vz-gutter-y: 0
    }

    .g-xl-1,.gx-xl-1 {
        --vz-gutter-x: 0.25rem
    }

    .g-xl-1,.gy-xl-1 {
        --vz-gutter-y: 0.25rem
    }

    .g-xl-2,.gx-xl-2 {
        --vz-gutter-x: 0.5rem
    }

    .g-xl-2,.gy-xl-2 {
        --vz-gutter-y: 0.5rem
    }

    .g-xl-3,.gx-xl-3 {
        --vz-gutter-x: 1rem
    }

    .g-xl-3,.gy-xl-3 {
        --vz-gutter-y: 1rem
    }

    .g-xl-4,.gx-xl-4 {
        --vz-gutter-x: 1.5rem
    }

    .g-xl-4,.gy-xl-4 {
        --vz-gutter-y: 1.5rem
    }

    .g-xl-5,.gx-xl-5 {
        --vz-gutter-x: 3rem
    }

    .g-xl-5,.gy-xl-5 {
        --vz-gutter-y: 3rem
    }
}

@media (min-width: 1400px) {
    .col-xxl {
        -webkit-box-flex:1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xxl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,.gx-xxl-0 {
        --vz-gutter-x: 0
    }

    .g-xxl-0,.gy-xxl-0 {
        --vz-gutter-y: 0
    }

    .g-xxl-1,.gx-xxl-1 {
        --vz-gutter-x: 0.25rem
    }

    .g-xxl-1,.gy-xxl-1 {
        --vz-gutter-y: 0.25rem
    }

    .g-xxl-2,.gx-xxl-2 {
        --vz-gutter-x: 0.5rem
    }

    .g-xxl-2,.gy-xxl-2 {
        --vz-gutter-y: 0.5rem
    }

    .g-xxl-3,.gx-xxl-3 {
        --vz-gutter-x: 1rem
    }

    .g-xxl-3,.gy-xxl-3 {
        --vz-gutter-y: 1rem
    }

    .g-xxl-4,.gx-xxl-4 {
        --vz-gutter-x: 1.5rem
    }

    .g-xxl-4,.gy-xxl-4 {
        --vz-gutter-y: 1.5rem
    }

    .g-xxl-5,.gx-xxl-5 {
        --vz-gutter-x: 3rem
    }

    .g-xxl-5,.gy-xxl-5 {
        --vz-gutter-y: 3rem
    }
}

.table {
    --vz-table-color: var(--vz-body-color);
    --vz-table-bg: transparent;
    --vz-table-border-color: var(--vz-border-color);
    --vz-table-accent-bg: transparent;
    --vz-table-striped-color: var(--vz-body-color);
    --vz-table-striped-bg: rgba(var(--vz-dark-rgb), 0.02);
    --vz-table-active-color: var(--vz-body-color);
    --vz-table-active-bg: rgba(var(--vz-light-rgb), 1);
    --vz-table-hover-color: var(--vz-body-color);
    --vz-table-hover-bg: rgba(var(--vz-light-rgb), 1);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--vz-table-color);
    vertical-align: top;
    border-color: var(--vz-table-border-color)
}

.table>:not(caption)>*>* {
    padding: .75rem .6rem;
    background-color: var(--vz-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--vz-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--vz-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table-group-divider {
    border-top: 2px solid var(--vz-border-color)
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .3rem .25rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-borderless>:not(:first-child) {
    border-top-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --vz-table-accent-bg: var(--vz-table-striped-bg);
    color: var(--vz-table-striped-color)
}

.table-striped-columns>:not(caption)>tr>:nth-child(even) {
    --vz-table-accent-bg: var(--vz-table-striped-bg);
    color: var(--vz-table-striped-color)
}

.table-active {
    --vz-table-accent-bg: var(--vz-table-active-bg);
    color: var(--vz-table-active-color)
}

.table-hover>tbody>tr:hover>* {
    --vz-table-accent-bg: var(--vz-table-hover-bg);
    color: var(--vz-table-hover-color)
}

.table-primary {
    --vz-table-color: #000;
    --vz-table-bg: #d9dce7;
    --vz-table-border-color: #c3c6d0;
    --vz-table-striped-bg: #d5d8e2;
    --vz-table-striped-color: #000;
    --vz-table-active-bg: black;
    --vz-table-active-color: #fff;
    --vz-table-hover-bg: black;
    --vz-table-hover-color: #fff;
    color: var(--vz-table-color);
    border-color: var(--vz-table-border-color)
}

.table-secondary {
    --vz-table-color: #000;
    --vz-table-bg: #d7e4fc;
    --vz-table-border-color: #c2cde3;
    --vz-table-striped-bg: #d3dff7;
    --vz-table-striped-color: #000;
    --vz-table-active-bg: black;
    --vz-table-active-color: #fff;
    --vz-table-hover-bg: black;
    --vz-table-hover-color: #fff;
    color: var(--vz-table-color);
    border-color: var(--vz-table-border-color)
}

.table-success {
    --vz-table-color: #000;
    --vz-table-bg: #cef0eb;
    --vz-table-border-color: #b9d8d4;
    --vz-table-striped-bg: #caebe6;
    --vz-table-striped-color: #000;
    --vz-table-active-bg: black;
    --vz-table-active-color: #fff;
    --vz-table-hover-bg: black;
    --vz-table-hover-color: #fff;
    color: var(--vz-table-color);
    border-color: var(--vz-table-border-color)
}

.table-info {
    --vz-table-color: #000;
    --vz-table-bg: #d4ebf8;
    --vz-table-border-color: #bfd4df;
    --vz-table-striped-bg: #d0e6f3;
    --vz-table-striped-color: #000;
    --vz-table-active-bg: black;
    --vz-table-active-color: #fff;
    --vz-table-hover-bg: black;
    --vz-table-hover-color: #fff;
    color: var(--vz-table-color);
    border-color: var(--vz-table-border-color)
}

.table-warning {
    --vz-table-color: #000;
    --vz-table-bg: #fdf1db;
    --vz-table-border-color: #e4d9c5;
    --vz-table-striped-bg: #f8ecd7;
    --vz-table-striped-color: #000;
    --vz-table-active-bg: black;
    --vz-table-active-color: #fff;
    --vz-table-hover-bg: black;
    --vz-table-hover-color: #fff;
    color: var(--vz-table-color);
    border-color: var(--vz-table-border-color)
}

.table-danger {
    --vz-table-color: #000;
    --vz-table-bg: #fce0da;
    --vz-table-border-color: #e3cac4;
    --vz-table-striped-bg: #f7dcd6;
    --vz-table-striped-color: #000;
    --vz-table-active-bg: black;
    --vz-table-active-color: #fff;
    --vz-table-hover-bg: black;
    --vz-table-hover-color: #fff;
    color: var(--vz-table-color);
    border-color: var(--vz-table-border-color)
}

.table-light {
    --vz-table-color: #000;
    --vz-table-bg: #f3f6f9;
    --vz-table-border-color: #dbdde0;
    --vz-table-striped-bg: #eef1f4;
    --vz-table-striped-color: #000;
    --vz-table-active-bg: black;
    --vz-table-active-color: #fff;
    --vz-table-hover-bg: black;
    --vz-table-hover-color: #fff;
    color: var(--vz-table-color);
    border-color: var(--vz-table-border-color)
}

.table-dark {
    --vz-table-color: #fff;
    --vz-table-bg: #212529;
    --vz-table-border-color: #373b3e;
    --vz-table-striped-bg: #25292d;
    --vz-table-striped-color: #fff;
    --vz-table-active-bg: white;
    --vz-table-active-color: #000;
    --vz-table-hover-bg: white;
    --vz-table-hover-color: #000;
    color: var(--vz-table-color);
    border-color: var(--vz-table-border-color)
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.7rem + 1px);
    padding-bottom: calc(.7rem + 1px);
    font-size: 1.015625rem
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .7109375rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: #878a99
}

.form-control {
    display: block;
    width: 100%;
    padding: .5rem .9rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--vz-body-color);
    background-color: var(--vz-input-bg);
    background-clip: padding-box;
    border: 1px solid var(--vz-input-border);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: var(--vz-body-color);
    background-color: var(--vz-input-bg);
    border-color: var(--vz-input-focus-border);
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(64,81,137,.25);
    box-shadow: 0 0 0 0 rgba(64,81,137,.25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::-webkit-input-placeholder {
    color: #878a99;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #878a99;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #878a99;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #878a99;
    opacity: 1
}

.form-control::placeholder {
    color: #878a99;
    opacity: 1
}

.form-control:disabled {
    background-color: var(--vz-input-disabled-bg);
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    padding: .5rem .9rem;
    margin: -.5rem -.9rem;
    -webkit-margin-end: .9rem;
    margin-inline-end:.9rem;color: var(--vz-body-color);
    background-color: var(--vz-input-group-addon-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:1px;border-radius: 0;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}

.form-control::file-selector-button {
    padding: .5rem .9rem;
    margin: -.5rem -.9rem;
    -webkit-margin-end: .9rem;
    margin-inline-end:.9rem;color: var(--vz-body-color);
    background-color: var(--vz-input-group-addon-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:1px;border-radius: 0;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }

    .form-control::file-selector-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: var(--vz-input-group-addon-bg)
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--vz-input-group-addon-bg)
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .5rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--vz-body-color);
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext:focus {
    outline: 0
}

.form-control-plaintext.form-control-lg,.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .7109375rem;
    border-radius: .2rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end:.5rem}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end:.5rem}

.form-control-lg {
    min-height: calc(1.5em + 1.4rem + 2px);
    padding: .7rem 1.2rem;
    font-size: 1.015625rem;
    border-radius: .3rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .7rem 1.2rem;
    margin: -.7rem -1.2rem;
    -webkit-margin-end: 1.2rem;
    margin-inline-end:1.2rem}

.form-control-lg::file-selector-button {
    padding: .7rem 1.2rem;
    margin: -.7rem -1.2rem;
    -webkit-margin-end: 1.2rem;
    margin-inline-end:1.2rem}

textarea.form-control {
    min-height: calc(1.5em + 1rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1.4rem + 2px)
}

.form-control-color {
    width: 3rem;
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    border: 0!important;
    border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
    border-radius: .25rem
}

.form-control-color.form-control-sm {
    height: calc(1.5em + .5rem + 2px)
}

.form-control-color.form-control-lg {
    height: calc(1.5em + 1.4rem + 2px)
}

.form-select {
    display: block;
    width: 100%;
    padding: .5rem 2.7rem .5rem .9rem;
    -moz-padding-start: calc(.9rem - 3px);
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--vz-body-color);
    background-color: var(--vz-input-bg);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .9rem center;
    background-size: 16px 12px;
    border: 1px solid var(--vz-input-border);
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-select {
        -webkit-transition: none;
        transition: none
    }
}

.form-select:focus {
    border-color: var(--vz-input-focus-border);
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(64,81,137,.25);
    box-shadow: 0 0 0 0 rgba(64,81,137,.25)
}

.form-select[multiple],.form-select[size]:not([size="1"]) {
    padding-right: .9rem;
    background-image: none
}

.form-select:disabled {
    background-color: var(--vz-input-disabled-bg)
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--vz-body-color)
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .7109375rem;
    border-radius: .2rem
}

.form-select-lg {
    padding-top: .7rem;
    padding-bottom: .7rem;
    padding-left: 1.2rem;
    font-size: 1.015625rem;
    border-radius: .3rem
}

.form-check {
    display: block;
    min-height: 1.21875rem;
    padding-left: 1.6em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.6em
}

.form-check-reverse {
    padding-right: 1.6em;
    padding-left: 0;
    text-align: right
}

.form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.6em;
    margin-left: 0
}

.form-check-input {
    width: 1.1em;
    height: 1.1em;
    margin-top: .2em;
    vertical-align: top;
    background-color: var(--vz-input-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid var(--vz-input-check-border);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    print-color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    -webkit-filter: brightness(90%);
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: var(--vz-input-focus-border);
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(64,81,137,.25);
    box-shadow: 0 0 0 0 rgba(64,81,137,.25)
}

.form-check-input:checked {
    background-color: #405189;
    border-color: #405189
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #405189;
    border-color: #405189;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .5;
    background-color: #ccc;
}

.form-check-input:disabled~.form-check-label,.form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    -webkit-transition: background-position .15s ease-in-out;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        -webkit-transition: none;
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23a0a8c4'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0
}

.form-switch.form-check-reverse .form-check-input {
    margin-right: -2.5em;
    margin-left: 0
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none
}

.btn-check:disabled+.btn,.btn-check[disabled]+.btn {
    pointer-events: none;
    -webkit-filter: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #f3f3f9,0 0 0 0 rgba(64,81,137,.25);
    box-shadow: 0 0 0 1px #f3f3f9,0 0 0 0 rgba(64,81,137,.25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #f3f3f9,0 0 0 0 rgba(64,81,137,.25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #405189;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #c6cbdc
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--vz-gray-300);
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #405189;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #c6cbdc
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--vz-gray-300);
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.form-floating {
    position: relative
}

.form-floating>.form-control,.form-floating>.form-control-plaintext,.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem .9rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: opacity .1s ease-in-out,-webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,-webkit-transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out,-webkit-transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        -webkit-transition: none;
        transition: none
    }
}

.form-floating>.form-control,.form-floating>.form-control-plaintext {
    padding: 1rem .9rem
}

.form-floating>.form-control-plaintext::-webkit-input-placeholder,.form-floating>.form-control::-webkit-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::-moz-placeholder,.form-floating>.form-control::-moz-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:-ms-input-placeholder,.form-floating>.form-control:-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::-ms-input-placeholder,.form-floating>.form-control::-ms-input-placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext::placeholder,.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control-plaintext:not(:-moz-placeholder-shown),.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:not(:-ms-input-placeholder),.form-floating>.form-control:not(:-ms-input-placeholder) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:focus,.form-floating>.form-control-plaintext:not(:placeholder-shown),.form-floating>.form-control:focus,.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control-plaintext:-webkit-autofill,.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label,.form-floating>.form-control:focus~label,.form-floating>.form-control:not(:placeholder-shown)~label,.form-floating>.form-select~label {
    opacity: .65;
    -webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    -webkit-transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control-plaintext~label {
    border-width: 1px 0
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,.input-group>.form-floating,.input-group>.form-select {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,.input-group>.form-floating:focus-within,.input-group>.form-select:focus {
    z-index: 5
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 5
}

.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .5rem .9rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--vz-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--vz-input-group-addon-bg);
    border: 1px solid var(--vz-input-border);
    border-radius: .25rem
}

.input-group-lg>.btn,.input-group-lg>.form-control,.input-group-lg>.form-select,.input-group-lg>.input-group-text {
    padding: .7rem 1.2rem;
    font-size: 1.015625rem;
    border-radius: .3rem
}

.input-group-sm>.btn,.input-group-sm>.form-control,.input-group-sm>.form-select,.input-group-sm>.input-group-text {
    padding: .25rem .5rem;
    font-size: .7109375rem;
    border-radius: .2rem
}

.input-group-lg>.form-select,.input-group-sm>.form-select {
    padding-right: 3.6rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.form-floating:not(:first-child)>.form-control,.input-group>.form-floating:not(:first-child)>.form-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #0ab39c
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .4rem .7rem;
    margin-top: .1rem;
    font-size: .7109375rem;
    color: #fff;
    background-color: rgba(10,179,156,.9);
    border-radius: .2rem
}

.is-valid~.valid-feedback,.is-valid~.valid-tooltip,.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,.was-validated .form-control:valid {
    border-color: #0ab39c;
    padding-right: calc(1.5em + 1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230ab39c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .25rem) center;
    background-size: calc(.75em + .5rem) calc(.75em + .5rem)
}

.form-control.is-valid:focus,.was-validated .form-control:valid:focus {
    border-color: #0ab39c;
    -webkit-box-shadow: 0 0 0 0 rgba(10,179,156,.25);
    box-shadow: 0 0 0 0 rgba(10,179,156,.25)
}

.was-validated textarea.form-control:valid,textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1rem);
    background-position: top calc(.375em + .25rem) right calc(.375em + .25rem)
}

.form-select.is-valid,.was-validated .form-select:valid {
    border-color: #0ab39c
}

.form-select.is-valid:not([multiple]):not([size]),.form-select.is-valid:not([multiple])[size="1"],.was-validated .form-select:valid:not([multiple]):not([size]),.was-validated .form-select:valid:not([multiple])[size="1"] {
    padding-right: 4.95rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230ab39c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .9rem center,center right 2.7rem;
    background-size: 16px 12px,calc(.75em + .5rem) calc(.75em + .5rem)
}

.form-select.is-valid:focus,.was-validated .form-select:valid:focus {
    border-color: #0ab39c;
    -webkit-box-shadow: 0 0 0 0 rgba(10,179,156,.25);
    box-shadow: 0 0 0 0 rgba(10,179,156,.25)
}

.form-control-color.is-valid,.was-validated .form-control-color:valid {
    width: calc(3rem + calc(1.5em + 1rem))
}

.form-check-input.is-valid,.was-validated .form-check-input:valid {
    border-color: #0ab39c
}

.form-check-input.is-valid:checked,.was-validated .form-check-input:valid:checked {
    background-color: #0ab39c
}

.form-check-input.is-valid:focus,.was-validated .form-check-input:valid:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(10,179,156,.25);
    box-shadow: 0 0 0 0 rgba(10,179,156,.25)
}

.form-check-input.is-valid~.form-check-label,.was-validated .form-check-input:valid~.form-check-label {
    color: #0ab39c
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group>.form-control:not(:focus).is-valid,.input-group>.form-floating:not(:focus-within).is-valid,.input-group>.form-select:not(:focus).is-valid,.was-validated .input-group>.form-control:not(:focus):valid,.was-validated .input-group>.form-floating:not(:focus-within):valid,.was-validated .input-group>.form-select:not(:focus):valid {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #f06548
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .4rem .7rem;
    margin-top: .1rem;
    font-size: .7109375rem;
    color: #fff;
    background-color: rgba(240,101,72,.9);
    border-radius: .2rem
}

.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip,.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,.was-validated .form-control:invalid {
    border-color: #f06548;
    padding-right: calc(1.5em + 1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f06548'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f06548' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .25rem) center;
    background-size: calc(.75em + .5rem) calc(.75em + .5rem)
}

.form-control.is-invalid:focus,.was-validated .form-control:invalid:focus {
    border-color: #f06548;
    -webkit-box-shadow: 0 0 0 0 rgba(240,101,72,.25);
    box-shadow: 0 0 0 0 rgba(240,101,72,.25)
}

.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1rem);
    background-position: top calc(.375em + .25rem) right calc(.375em + .25rem)
}

.form-select.is-invalid,.was-validated .form-select:invalid {
    border-color: #f06548
}

.form-select.is-invalid:not([multiple]):not([size]),.form-select.is-invalid:not([multiple])[size="1"],.was-validated .form-select:invalid:not([multiple]):not([size]),.was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.95rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f06548'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f06548' stroke='none'/%3e%3c/svg%3e");
    background-position: right .9rem center,center right 2.7rem;
    background-size: 16px 12px,calc(.75em + .5rem) calc(.75em + .5rem)
}

.form-select.is-invalid:focus,.was-validated .form-select:invalid:focus {
    border-color: #f06548;
    -webkit-box-shadow: 0 0 0 0 rgba(240,101,72,.25);
    box-shadow: 0 0 0 0 rgba(240,101,72,.25)
}

.form-control-color.is-invalid,.was-validated .form-control-color:invalid {
    width: calc(3rem + calc(1.5em + 1rem))
}

.form-check-input.is-invalid,.was-validated .form-check-input:invalid {
    border-color: #f06548
}

.form-check-input.is-invalid:checked,.was-validated .form-check-input:invalid:checked {
    background-color: #f06548
}

.form-check-input.is-invalid:focus,.was-validated .form-check-input:invalid:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(240,101,72,.25);
    box-shadow: 0 0 0 0 rgba(240,101,72,.25)
}

.form-check-input.is-invalid~.form-check-label,.was-validated .form-check-input:invalid~.form-check-label {
    color: #f06548
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group>.form-control:not(:focus).is-invalid,.input-group>.form-floating:not(:focus-within).is-invalid,.input-group>.form-select:not(:focus).is-invalid,.was-validated .input-group>.form-control:not(:focus):invalid,.was-validated .input-group>.form-floating:not(:focus-within):invalid,.was-validated .input-group>.form-select:not(:focus):invalid {
    z-index: 4
}

.btn {
    --vz-btn-padding-x: 0.9rem;
    --vz-btn-padding-y: 0.5rem;
    --vz-btn-font-size: 0.8125rem;
    --vz-btn-font-weight: 400;
    --vz-btn-line-height: 1.5;
    --vz-btn-color: #212529;
    --vz-btn-bg: transparent;
    --vz-btn-border-width: 1px;
    --vz-btn-border-color: transparent;
    --vz-btn-border-radius: 0.25rem;
    --vz-btn-hover-border-color: transparent;
    --vz-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
    --vz-btn-disabled-opacity: 0.65;
    --vz-btn-focus-box-shadow: 0 0 0 0 rgba(var(--vz-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--vz-btn-padding-y) var(--vz-btn-padding-x);
    font-family: var(--vz-btn-font-family);
    font-size: var(--vz-btn-font-size);
    font-weight: var(--vz-btn-font-weight);
    line-height: var(--vz-btn-line-height);
    color: var(--vz-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: var(--vz-btn-border-width) solid var(--vz-btn-border-color);
    border-radius: var(--vz-btn-border-radius);
    background-color: var(--vz-btn-bg);
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        -webkit-transition: none;
        transition: none
    }
}

.btn:hover {
    color: var(--vz-btn-hover-color);
    background-color: var(--vz-btn-hover-bg);
    border-color: var(--vz-btn-hover-border-color)
}

.btn-check+.btn:hover {
    color: var(--vz-btn-color);
    background-color: var(--vz-btn-bg);
    border-color: var(--vz-btn-border-color)
}

.btn:focus-visible {
    color: var(--vz-btn-hover-color);
    background-color: var(--vz-btn-hover-bg);
    border-color: var(--vz-btn-hover-border-color);
    outline: 0;
    -webkit-box-shadow: var(--vz-btn-focus-box-shadow);
    box-shadow: var(--vz-btn-focus-box-shadow)
}

.btn-check:focus-visible+.btn {
    border-color: var(--vz-btn-hover-border-color);
    outline: 0;
    -webkit-box-shadow: var(--vz-btn-focus-box-shadow);
    box-shadow: var(--vz-btn-focus-box-shadow)
}

.btn-check:checked+.btn,.btn.active,.btn.show,.btn:first-child:active,:not(.btn-check)+.btn:active {
    color: var(--vz-btn-active-color);
    background-color: var(--vz-btn-active-bg);
    border-color: var(--vz-btn-active-border-color)
}

.btn-check:checked+.btn:focus-visible,.btn.active:focus-visible,.btn.show:focus-visible,.btn:first-child:active:focus-visible,:not(.btn-check)+.btn:active:focus-visible {
    -webkit-box-shadow: var(--vz-btn-focus-box-shadow);
    box-shadow: var(--vz-btn-focus-box-shadow)
}

.btn.disabled,.btn:disabled,fieldset:disabled .btn {
    color: var(--vz-btn-disabled-color);
    pointer-events: none;
    background-color: var(--vz-btn-disabled-bg);
    border-color: var(--vz-btn-disabled-border-color);
    opacity: var(--vz-btn-disabled-opacity)
}

.btn-primary {
    --vz-btn-color: #fff;
    --vz-btn-bg: #405189;
    --vz-btn-border-color: #405189;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #364574;
    --vz-btn-hover-border-color: #33416e;
    --vz-btn-focus-shadow-rgb: 93,107,155;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #33416e;
    --vz-btn-active-border-color: #303d67;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #405189;
    --vz-btn-disabled-border-color: #405189
}

.btn-secondary {
    --vz-btn-color: #fff;
    --vz-btn-bg: #3577f1;
    --vz-btn-border-color: #3577f1;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #2d65cd;
    --vz-btn-hover-border-color: #2a5fc1;
    --vz-btn-focus-shadow-rgb: 83,139,243;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #2a5fc1;
    --vz-btn-active-border-color: #2859b5;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #3577f1;
    --vz-btn-disabled-border-color: #3577f1
}

.btn-success {
    --vz-btn-color: #fff;
    --vz-btn-bg: #0ab39c;
    --vz-btn-border-color: #0ab39c;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #099885;
    --vz-btn-hover-border-color: #088f7d;
    --vz-btn-focus-shadow-rgb: 47,190,171;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #088f7d;
    --vz-btn-active-border-color: #088675;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #0ab39c;
    --vz-btn-disabled-border-color: #0ab39c
}

.btn-info {
    --vz-btn-color: #fff;
    --vz-btn-bg: #299cdb;
    --vz-btn-border-color: #299cdb;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #2385ba;
    --vz-btn-hover-border-color: #217daf;
    --vz-btn-focus-shadow-rgb: 73,171,224;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #217daf;
    --vz-btn-active-border-color: #1f75a4;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #299cdb;
    --vz-btn-disabled-border-color: #299cdb
}

.btn-warning {
    --vz-btn-color: #fff;
    --vz-btn-bg: #f7b84b;
    --vz-btn-border-color: #f7b84b;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #d29c40;
    --vz-btn-hover-border-color: #c6933c;
    --vz-btn-focus-shadow-rgb: 248,195,102;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #c6933c;
    --vz-btn-active-border-color: #b98a38;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #f7b84b;
    --vz-btn-disabled-border-color: #f7b84b
}

.btn-danger {
    --vz-btn-color: #fff;
    --vz-btn-bg: #f06548;
    --vz-btn-border-color: #f06548;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #cc563d;
    --vz-btn-hover-border-color: #c0513a;
    --vz-btn-focus-shadow-rgb: 242,124,99;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #c0513a;
    --vz-btn-active-border-color: #b44c36;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #f06548;
    --vz-btn-disabled-border-color: #f06548
}

.btn-light {
    --vz-btn-color: #000;
    --vz-btn-bg: #f3f6f9;
    --vz-btn-border-color: #f3f6f9;
    --vz-btn-hover-color: #000;
    --vz-btn-hover-bg: #cfd1d4;
    --vz-btn-hover-border-color: #c2c5c7;
    --vz-btn-focus-shadow-rgb: 207,209,212;
    --vz-btn-active-color: #000;
    --vz-btn-active-bg: #c2c5c7;
    --vz-btn-active-border-color: #b6b9bb;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #000;
    --vz-btn-disabled-bg: #f3f6f9;
    --vz-btn-disabled-border-color: #f3f6f9
}

.btn-dark {
    --vz-btn-color: #fff;
    --vz-btn-bg: #212529;
    --vz-btn-border-color: #212529;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #424649;
    --vz-btn-hover-border-color: #373b3e;
    --vz-btn-focus-shadow-rgb: 66,70,73;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #4d5154;
    --vz-btn-active-border-color: #373b3e;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #212529;
    --vz-btn-disabled-border-color: #212529
}

.btn-outline-primary {
    --vz-btn-color: #405189;
    --vz-btn-border-color: #405189;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #405189;
    --vz-btn-hover-border-color: #405189;
    --vz-btn-focus-shadow-rgb: 64,81,137;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #405189;
    --vz-btn-active-border-color: #405189;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #405189;
    --vz-btn-disabled-bg: transparent;
    --vz-btn-disabled-border-color: #405189;
    --vz-gradient: none
}

.btn-outline-secondary {
    --vz-btn-color: #3577f1;
    --vz-btn-border-color: #3577f1;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #3577f1;
    --vz-btn-hover-border-color: #3577f1;
    --vz-btn-focus-shadow-rgb: 53,119,241;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #3577f1;
    --vz-btn-active-border-color: #3577f1;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #3577f1;
    --vz-btn-disabled-bg: transparent;
    --vz-btn-disabled-border-color: #3577f1;
    --vz-gradient: none
}

.btn-outline-success {
    --vz-btn-color: #689f38;
    --vz-btn-border-color: #689f38;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #689f38;
    --vz-btn-hover-border-color: #689f38;
    --vz-btn-focus-shadow-rgb: 10,179,156;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #689f38;
    --vz-btn-active-border-color: #689f38;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #689f38;
    --vz-btn-disabled-bg: transparent;
    --vz-btn-disabled-border-color: #689f38;
    --vz-gradient: none
}

.btn-outline-info {
    --vz-btn-color: #299cdb;
    --vz-btn-border-color: #299cdb;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #299cdb;
    --vz-btn-hover-border-color: #299cdb;
    --vz-btn-focus-shadow-rgb: 41,156,219;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #299cdb;
    --vz-btn-active-border-color: #299cdb;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #299cdb;
    --vz-btn-disabled-bg: transparent;
    --vz-btn-disabled-border-color: #299cdb;
    --vz-gradient: none
}

.btn-outline-warning {
    --vz-btn-color: #f7b84b;
    --vz-btn-border-color: #f7b84b;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #f7b84b;
    --vz-btn-hover-border-color: #f7b84b;
    --vz-btn-focus-shadow-rgb: 247,184,75;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #f7b84b;
    --vz-btn-active-border-color: #f7b84b;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #f7b84b;
    --vz-btn-disabled-bg: transparent;
    --vz-btn-disabled-border-color: #f7b84b;
    --vz-gradient: none
}

.btn-outline-danger {
    --vz-btn-color: #f06548;
    --vz-btn-border-color: #f06548;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #f06548;
    --vz-btn-hover-border-color: #f06548;
    --vz-btn-focus-shadow-rgb: 240,101,72;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #f06548;
    --vz-btn-active-border-color: #f06548;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #f06548;
    --vz-btn-disabled-bg: transparent;
    --vz-btn-disabled-border-color: #f06548;
    --vz-gradient: none
}

.btn-outline-light {
    --vz-btn-color: #f3f6f9;
    --vz-btn-border-color: #f3f6f9;
    --vz-btn-hover-color: #000;
    --vz-btn-hover-bg: #f3f6f9;
    --vz-btn-hover-border-color: #f3f6f9;
    --vz-btn-focus-shadow-rgb: 243,246,249;
    --vz-btn-active-color: #000;
    --vz-btn-active-bg: #f3f6f9;
    --vz-btn-active-border-color: #f3f6f9;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #f3f6f9;
    --vz-btn-disabled-bg: transparent;
    --vz-btn-disabled-border-color: #f3f6f9;
    --vz-gradient: none
}

.btn-outline-dark {
    --vz-btn-color: #212529;
    --vz-btn-border-color: #212529;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #212529;
    --vz-btn-hover-border-color: #212529;
    --vz-btn-focus-shadow-rgb: 33,37,41;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #212529;
    --vz-btn-active-border-color: #212529;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #212529;
    --vz-btn-disabled-bg: transparent;
    --vz-btn-disabled-border-color: #212529;
    --vz-gradient: none
}

.btn-link {
    --vz-btn-font-weight: 400;
    --vz-btn-color: var(--vz-link-color);
    --vz-btn-bg: transparent;
    --vz-btn-border-color: transparent;
    --vz-btn-hover-color: var(--vz-link-hover-color);
    --vz-btn-hover-border-color: transparent;
    --vz-btn-active-color: var(--vz-link-hover-color);
    --vz-btn-active-border-color: transparent;
    --vz-btn-disabled-color: #878a99;
    --vz-btn-disabled-border-color: transparent;
    --vz-btn-box-shadow: none;
    --vz-btn-focus-shadow-rgb: 93,107,155;
    text-decoration: underline
}

.btn-link:focus-visible {
    color: var(--vz-btn-color)
}

.btn-link:hover {
    color: var(--vz-btn-hover-color)
}

.btn-group-lg>.btn,.btn-lg {
    --vz-btn-padding-y: 0.7rem;
    --vz-btn-padding-x: 1.2rem;
    --vz-btn-font-size: 1.015625rem;
    --vz-btn-border-radius: 0.3rem
}

.btn-group-sm>.btn,.btn-sm {
    --vz-btn-padding-y: 0.25rem;
    --vz-btn-padding-x: 0.5rem;
    --vz-btn-font-size: 0.7109375rem;
    --vz-btn-border-radius: 0.2rem
}

.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        -webkit-transition: none;
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        -webkit-transition: none;
        transition: none
    }
}

.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    -webkit-transition: width .35s ease;
    transition: width .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing.collapse-horizontal {
        -webkit-transition: none;
        transition: none
    }
}

.dropdown,.dropdown-center,.dropend,.dropstart,.dropup,.dropup-center {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-menu {
    --vz-dropdown-zindex: 1000;
    --vz-dropdown-min-width: 10rem;
    --vz-dropdown-padding-x: 0;
    --vz-dropdown-padding-y: 0.5rem;
    --vz-dropdown-spacer: 0.125rem;
    --vz-dropdown-font-size: 0.8125rem;
    --vz-dropdown-color: var(--vz-body-color);
    --vz-dropdown-bg: #fff;
    --vz-dropdown-border-color: var(--vz-border-color);
    --vz-dropdown-border-radius: 0.3rem;
    --vz-dropdown-border-width: 0;
    --vz-dropdown-inner-border-radius: 0.3rem;
    --vz-dropdown-divider-bg: var(--vz-border-color);
    --vz-dropdown-divider-margin-y: 0.5rem;
    --vz-dropdown-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    --vz-dropdown-link-color: #212529;
    --vz-dropdown-link-hover-color: #1e2125;
    --vz-dropdown-link-hover-bg: #f3f6f9;
    --vz-dropdown-link-active-color: #1e2125;
    --vz-dropdown-link-active-bg: #f3f6f9;
    --vz-dropdown-link-disabled-color: #adb5bd;
    --vz-dropdown-item-padding-x: 1.2rem;
    --vz-dropdown-item-padding-y: 0.35rem;
    --vz-dropdown-header-color: #878a99;
    --vz-dropdown-header-padding-x: 1.2rem;
    --vz-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: var(--vz-dropdown-zindex);
    display: none;
    min-width: var(--vz-dropdown-min-width);
    padding: var(--vz-dropdown-padding-y) var(--vz-dropdown-padding-x);
    margin: 0;
    font-size: var(--vz-dropdown-font-size);
    color: var(--vz-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--vz-dropdown-bg);
    background-clip: padding-box;
    border: var(--vz-dropdown-border-width) solid var(--vz-dropdown-border-color);
    border-radius: var(--vz-dropdown-border-radius)
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--vz-dropdown-spacer)
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position:start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position:start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position:start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position:start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position:start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--vz-dropdown-spacer)
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--vz-dropdown-spacer)
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--vz-dropdown-spacer)
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: var(--vz-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--vz-dropdown-divider-bg);
    opacity: 1
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: var(--vz-dropdown-item-padding-y) var(--vz-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--vz-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,.dropdown-item:hover {
    color: var(--vz-dropdown-link-hover-color);
    background-color: var(--vz-dropdown-link-hover-bg)
}

.dropdown-item.active,.dropdown-item:active {
    color: var(--vz-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--vz-dropdown-link-active-bg)
}

.dropdown-item.disabled,.dropdown-item:disabled {
    color: var(--vz-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: var(--vz-dropdown-header-padding-y) var(--vz-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: .7109375rem;
    color: var(--vz-dropdown-header-color);
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: var(--vz-dropdown-item-padding-y) var(--vz-dropdown-item-padding-x);
    color: var(--vz-dropdown-link-color)
}

.dropdown-menu-dark {
    --vz-dropdown-color: #e9ebec;
    --vz-dropdown-bg: #343a40;
    --vz-dropdown-border-color: var(--vz-border-color);
    --vz-dropdown-link-color: #e9ebec;
    --vz-dropdown-link-hover-color: #fff;
    --vz-dropdown-divider-bg: var(--vz-border-color);
    --vz-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --vz-dropdown-link-active-color: #fff;
    --vz-dropdown-link-active-bg: #3f474e;
    --vz-dropdown-link-disabled-color: #adb5bd;
    --vz-dropdown-header-color: #adb5bd
}

.btn-group,.btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,.btn-group>.btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,.btn-group-vertical>.btn-check:focus+.btn,.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:hover,.btn-group>.btn-check:checked+.btn,.btn-group>.btn-check:focus+.btn,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus,.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group {
    border-radius: .25rem
}

.btn-group>.btn-group:not(:first-child),.btn-group>:not(.btn-check:first-child)+.btn {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,.btn-group>.btn.dropdown-toggle-split:first-child,.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,.btn-group>.btn:nth-child(n+3),.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .675rem;
    padding-left: .675rem
}

.dropdown-toggle-split::after,.dropend .dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,.btn-lg+.dropdown-toggle-split {
    padding-right: .9rem;
    padding-left: .9rem
}

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    --vz-nav-link-padding-x: 1rem;
    --vz-nav-link-padding-y: 0.5rem;
    --vz-nav-link-color: var(--vz-link-color);
    --vz-nav-link-hover-color: var(--vz-link-hover-color);
    --vz-nav-link-disabled-color: #878a99;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: var(--vz-nav-link-padding-y) var(--vz-nav-link-padding-x);
    font-size: var(--vz-nav-link-font-size);
    font-weight: var(--vz-nav-link-font-weight);
    color: var(--vz-nav-link-color);
    text-decoration: none;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        -webkit-transition: none;
        transition: none
    }
}

.nav-link:focus,.nav-link:hover {
    color: var(--vz-nav-link-hover-color)
}

.nav-link.disabled {
    color: var(--vz-nav-link-disabled-color);
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    --vz-nav-tabs-border-width: 1px;
    --vz-nav-tabs-border-color: var(--vz-gray-300);
    --vz-nav-tabs-border-radius: 0.25rem;
    --vz-nav-tabs-link-hover-border-color: var(--vz-gray-300) var(--vz-gray-300) var(--vz-gray-300);
    --vz-nav-tabs-link-active-color: #495057;
    --vz-nav-tabs-link-active-bg: var(--vz-nav-tabs-link-active-bg);
    --vz-nav-tabs-link-active-border-color: var(--vz-gray-300) var(--vz-gray-300) var(--vz-nav-tabs-link-active-bg);
    border-bottom: var(--vz-nav-tabs-border-width) solid var(--vz-nav-tabs-border-color)
}

.nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--vz-nav-tabs-border-width));
    background: 0 0;
    border: var(--vz-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--vz-nav-tabs-border-radius);
    border-top-right-radius: var(--vz-nav-tabs-border-radius)
}

.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: var(--vz-nav-tabs-link-hover-border-color)
}

.nav-tabs .nav-link.disabled,.nav-tabs .nav-link:disabled {
    color: var(--vz-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active {
    color: var(--vz-nav-tabs-link-active-color);
    background-color: var(--vz-nav-tabs-link-active-bg);
    border-color: var(--vz-nav-tabs-link-active-border-color)
}

.nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--vz-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills {
    --vz-nav-pills-border-radius: 0.25rem;
    --vz-nav-pills-link-active-color: #fff;
    --vz-nav-pills-link-active-bg: #405189
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: var(--vz-nav-pills-border-radius)
}

.nav-pills .nav-link:disabled {
    color: var(--vz-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
    color: var(--vz-nav-pills-link-active-color);
    background-color: var(--vz-nav-pills-link-active-bg)
}

.nav-fill .nav-item,.nav-fill>.nav-link {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,.nav-justified>.nav-link {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    --vz-navbar-padding-x: 0;
    --vz-navbar-padding-y: 0.5rem;
    --vz-navbar-color: rgba(0, 0, 0, 0.55);
    --vz-navbar-hover-color: rgba(0, 0, 0, 0.7);
    --vz-navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --vz-navbar-active-color: rgba(0, 0, 0, 0.9);
    --vz-navbar-brand-padding-y: 0.34765625rem;
    --vz-navbar-brand-margin-end: 1rem;
    --vz-navbar-brand-font-size: 1.015625rem;
    --vz-navbar-brand-color: rgba(0, 0, 0, 0.9);
    --vz-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
    --vz-navbar-nav-link-padding-x: 0.5rem;
    --vz-navbar-toggler-padding-y: 0.25rem;
    --vz-navbar-toggler-padding-x: 0.75rem;
    --vz-navbar-toggler-font-size: 1.015625rem;
    --vz-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --vz-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --vz-navbar-toggler-border-radius: 0.25rem;
    --vz-navbar-toggler-focus-width: 0;
    --vz-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--vz-navbar-padding-y) var(--vz-navbar-padding-x)
}

.navbar>.container,.navbar>.container-fluid,.navbar>.container-lg,.navbar>.container-md,.navbar>.container-sm,.navbar>.container-xl,.navbar>.container-xxl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    padding-top: var(--vz-navbar-brand-padding-y);
    padding-bottom: var(--vz-navbar-brand-padding-y);
    margin-right: var(--vz-navbar-brand-margin-end);
    font-size: var(--vz-navbar-brand-font-size);
    color: var(--vz-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap
}

.navbar-brand:focus,.navbar-brand:hover {
    color: var(--vz-navbar-brand-hover-color)
}

.navbar-nav {
    --vz-nav-link-padding-x: 0;
    --vz-nav-link-padding-y: 0.5rem;
    --vz-nav-link-color: var(--vz-navbar-color);
    --vz-nav-link-hover-color: var(--vz-navbar-hover-color);
    --vz-nav-link-disabled-color: var(--vz-navbar-disabled-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link.active,.navbar-nav .show>.nav-link {
    color: var(--vz-navbar-active-color)
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: var(--vz-navbar-color)
}

.navbar-text a,.navbar-text a:focus,.navbar-text a:hover {
    color: var(--vz-navbar-active-color)
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggler {
    padding: var(--vz-navbar-toggler-padding-y) var(--vz-navbar-toggler-padding-x);
    font-size: var(--vz-navbar-toggler-font-size);
    line-height: 1;
    color: var(--vz-navbar-color);
    background-color: transparent;
    border: var(--vz-border-width) solid var(--vz-navbar-toggler-border-color);
    border-radius: var(--vz-navbar-toggler-border-radius);
    -webkit-transition: var(--vz-navbar-toggler-transition);
    transition: var(--vz-navbar-toggler-transition)
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        -webkit-transition: none;
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 var(--vz-navbar-toggler-focus-width);
    box-shadow: 0 0 0 var(--vz-navbar-toggler-focus-width)
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--vz-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--vz-scroll-height,75vh);
    overflow-y: auto
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--vz-navbar-nav-link-padding-x);
        padding-left: var(--vz-navbar-nav-link-padding-x)
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }

    .navbar-expand-sm .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-sm .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--vz-navbar-nav-link-padding-x);
        padding-left: var(--vz-navbar-nav-link-padding-x)
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }

    .navbar-expand-md .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-md .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-md .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--vz-navbar-nav-link-padding-x);
        padding-left: var(--vz-navbar-nav-link-padding-x)
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--vz-navbar-nav-link-padding-x);
        padding-left: var(--vz-navbar-nav-link-padding-x)
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }

    .navbar-expand-xl .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xl .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

@media (min-width: 1400px) {
    .navbar-expand-xxl {
        -ms-flex-wrap:nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--vz-navbar-nav-link-padding-x);
        padding-left: var(--vz-navbar-nav-link-padding-x)
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }

    .navbar-expand-xxl .offcanvas {
        position: static;
        z-index: auto;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: auto!important;
        height: auto!important;
        visibility: visible!important;
        background-color: transparent!important;
        border: 0!important;
        -webkit-transform: none!important;
        transform: none!important;
        -webkit-transition: none;
        transition: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none
    }

    .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible
    }
}

.navbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: var(--vz-navbar-nav-link-padding-x);
    padding-left: var(--vz-navbar-nav-link-padding-x)
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: auto!important;
    height: auto!important;
    visibility: visible!important;
    background-color: transparent!important;
    border: 0!important;
    -webkit-transform: none!important;
    transform: none!important;
    -webkit-transition: none;
    transition: none
}

.navbar-expand .offcanvas .offcanvas-header {
    display: none
}

.navbar-expand .offcanvas .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible
}

.navbar-dark {
    --vz-navbar-color: rgba(255, 255, 255, 0.55);
    --vz-navbar-hover-color: rgba(255, 255, 255, 0.75);
    --vz-navbar-disabled-color: rgba(255, 255, 255, 0.25);
    --vz-navbar-active-color: #fff;
    --vz-navbar-brand-color: #fff;
    --vz-navbar-brand-hover-color: #fff;
    --vz-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
    --vz-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.card {
    --vz-card-spacer-y: 1rem;
    --vz-card-spacer-x: 1rem;
    --vz-card-title-spacer-y: 0.5rem;
    --vz-card-border-width: 0;
    --vz-card-border-color: rgba(0, 0, 0, 0.125);
    --vz-card-border-radius: 0.25rem;
    --vz-card-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    --vz-card-inner-border-radius: 0.25rem;
    --vz-card-cap-padding-y: 1rem;
    --vz-card-cap-padding-x: 1rem;
    --vz-card-cap-bg: #fff;
    --vz-card-bg: #fff;
    --vz-card-img-overlay-padding: 1rem;
    --vz-card-group-margin: 0.75rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    height: var(--vz-card-height);
    word-wrap: break-word;
    background-color: var(--vz-card-bg);
    background-clip: border-box;
    border: var(--vz-card-border-width) solid var(--vz-card-border-color);
    border-radius: var(--vz-card-border-radius)
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: var(--vz-card-inner-border-radius);
    border-top-right-radius: var(--vz-card-inner-border-radius)
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: var(--vz-card-inner-border-radius);
    border-bottom-left-radius: var(--vz-card-inner-border-radius)
}

.card>.card-header+.list-group,.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--vz-card-spacer-y) var(--vz-card-spacer-x);
    color: var(--vz-card-color)
}

.card-title {
    margin-bottom: var(--vz-card-title-spacer-y)
}

.card-subtitle {
    margin-top: calc(-.5 * var(--vz-card-title-spacer-y));
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link+.card-link {
    margin-left: var(--vz-card-spacer-x)
}

.card-header {
    padding: var(--vz-card-cap-padding-y) var(--vz-card-cap-padding-x);
    margin-bottom: 0;
    color: var(--vz-card-cap-color);
    background-color: var(--vz-card-cap-bg);
    border-bottom: var(--vz-card-border-width) solid var(--vz-card-border-color)
}

.card-header:first-child {
    border-radius: var(--vz-card-inner-border-radius) var(--vz-card-inner-border-radius) 0 0
}

.card-footer {
    padding: var(--vz-card-cap-padding-y) var(--vz-card-cap-padding-x);
    color: var(--vz-card-cap-color);
    background-color: var(--vz-card-cap-bg);
    border-top: var(--vz-card-border-width) solid var(--vz-card-border-color)
}

.card-footer:last-child {
    border-radius: 0 0 var(--vz-card-inner-border-radius) var(--vz-card-inner-border-radius)
}

.card-header-tabs {
    margin-right: calc(-.5 * var(--vz-card-cap-padding-x));
    margin-bottom: calc(-1 * var(--vz-card-cap-padding-y));
    margin-left: calc(-.5 * var(--vz-card-cap-padding-x));
    border-bottom: 0
}

.card-header-tabs .nav-link.active {
    background-color: var(--vz-card-bg);
    border-bottom-color: var(--vz-card-bg)
}

.card-header-pills {
    margin-right: calc(-.5 * var(--vz-card-cap-padding-x));
    margin-left: calc(-.5 * var(--vz-card-cap-padding-x))
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: var(--vz-card-img-overlay-padding);
    border-radius: var(--vz-card-inner-border-radius)
}

.card-img,.card-img-bottom,.card-img-top {
    width: 100%
}

.card-img,.card-img-top {
    border-top-left-radius: var(--vz-card-inner-border-radius);
    border-top-right-radius: var(--vz-card-inner-border-radius)
}

.card-img,.card-img-bottom {
    border-bottom-right-radius: var(--vz-card-inner-border-radius);
    border-bottom-left-radius: var(--vz-card-inner-border-radius)
}

.card-group>.card {
    margin-bottom: var(--vz-card-group-margin)
}

@media (min-width: 576px) {
    .card-group {
        display:-webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group>.card {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,.card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,.card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,.card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,.card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion {
    --vz-accordion-color: var(--vz-body-color);
    --vz-accordion-bg: #fff;
    --vz-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --vz-accordion-border-color: var(--vz-border-color);
    --vz-accordion-border-width: 1px;
    --vz-accordion-border-radius: 0.25rem;
    --vz-accordion-inner-border-radius: calc(0.25rem - 1px);
    --vz-accordion-btn-padding-x: 1.25rem;
    --vz-accordion-btn-padding-y: 1rem;
    --vz-accordion-btn-color: var(--vz-body-color);
    --vz-accordion-btn-bg: #fff;
    --vz-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--vz-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --vz-accordion-btn-icon-width: 0.75rem;
    --vz-accordion-btn-icon-transform: rotate(-180deg);
    --vz-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --vz-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233a497b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --vz-accordion-btn-focus-border-color: var(--vz-input-focus-border);
    --vz-accordion-btn-focus-box-shadow: inset 0 -1px 0 var(--vz-border-color);
    --vz-accordion-body-padding-x: 1.25rem;
    --vz-accordion-body-padding-y: 1rem;
    --vz-accordion-active-color: #3a497b;
    --vz-accordion-active-bg: rgba(64, 81, 137, 0.05)
}

.accordion-button {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: var(--vz-accordion-btn-padding-y) var(--vz-accordion-btn-padding-x);
    font-size: .8125rem;
    color: var(--vz-accordion-btn-color);
    text-align: left;
    background-color: var(--vz-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    -webkit-transition: var(--vz-accordion-transition);
    transition: var(--vz-accordion-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        -webkit-transition: none;
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: var(--vz-accordion-active-color);
    background-color: var(--vz-accordion-active-bg);
    -webkit-box-shadow: inset 0 calc(-1 * var(--vz-accordion-border-width)) 0 var(--vz-accordion-border-color);
    box-shadow: inset 0 calc(-1 * var(--vz-accordion-border-width)) 0 var(--vz-accordion-border-color)
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--vz-accordion-btn-active-icon);
    -webkit-transform: var(--vz-accordion-btn-icon-transform);
    transform: var(--vz-accordion-btn-icon-transform)
}

.accordion-button::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: var(--vz-accordion-btn-icon-width);
    height: var(--vz-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--vz-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--vz-accordion-btn-icon-width);
    -webkit-transition: var(--vz-accordion-btn-icon-transition);
    transition: var(--vz-accordion-btn-icon-transition)
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        -webkit-transition: none;
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--vz-accordion-btn-focus-border-color);
    outline: 0;
    -webkit-box-shadow: var(--vz-accordion-btn-focus-box-shadow);
    box-shadow: var(--vz-accordion-btn-focus-box-shadow)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    color: var(--vz-accordion-color);
    background-color: var(--vz-accordion-bg);
    border: var(--vz-accordion-border-width) solid var(--vz-accordion-border-color)
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--vz-accordion-border-radius);
    border-top-right-radius: var(--vz-accordion-border-radius)
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--vz-accordion-inner-border-radius);
    border-top-right-radius: var(--vz-accordion-inner-border-radius)
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--vz-accordion-border-radius);
    border-bottom-left-radius: var(--vz-accordion-border-radius)
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--vz-accordion-inner-border-radius);
    border-bottom-left-radius: var(--vz-accordion-inner-border-radius)
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--vz-accordion-border-radius);
    border-bottom-left-radius: var(--vz-accordion-border-radius)
}

.accordion-body {
    padding: var(--vz-accordion-body-padding-y) var(--vz-accordion-body-padding-x)
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button,.accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0
}

.breadcrumb {
    --vz-breadcrumb-padding-x: 0;
    --vz-breadcrumb-padding-y: 0;
    --vz-breadcrumb-margin-bottom: 1rem;
    --vz-breadcrumb-divider-color: #878a99;
    --vz-breadcrumb-item-padding-x: 0.5rem;
    --vz-breadcrumb-item-active-color: #878a99;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: var(--vz-breadcrumb-padding-y) var(--vz-breadcrumb-padding-x);
    margin-bottom: var(--vz-breadcrumb-margin-bottom);
    font-size: var(--vz-breadcrumb-font-size);
    list-style: none;
    background-color: var(--vz-breadcrumb-bg);
    border-radius: var(--vz-breadcrumb-border-radius)
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: var(--vz-breadcrumb-item-padding-x)
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--vz-breadcrumb-item-padding-x);
    color: var(--vz-breadcrumb-divider-color);
    content: var(--vz-breadcrumb-divider, "\f0142")
}

.breadcrumb-item.active {
    color: var(--vz-breadcrumb-item-active-color)
}

.pagination {
    --vz-pagination-padding-x: 0.75rem;
    --vz-pagination-padding-y: 0.375rem;
    --vz-pagination-font-size: 0.8125rem;
    --vz-pagination-color: var(--vz-link-color);
    --vz-pagination-bg: var(--vz-card-bg-custom);
    --vz-pagination-border-width: 1px;
    --vz-pagination-border-color: var(--vz-border-color);
    --vz-pagination-border-radius: 0.25rem;
    --vz-pagination-hover-color: var(--vz-link-hover-color);
    --vz-pagination-hover-bg: #eff2f7;
    --vz-pagination-hover-border-color: var(--vz-border-color);
    --vz-pagination-focus-color: var(--vz-link-hover-color);
    --vz-pagination-focus-bg: #eff2f7;
    --vz-pagination-focus-box-shadow: 0 0 0 0 rgba(64, 81, 137, 0.25);
    --vz-pagination-active-color: #fff;
    --vz-pagination-active-bg: #405189;
    --vz-pagination-active-border-color: #405189;
    --vz-pagination-disabled-color: #878a99;
    --vz-pagination-disabled-bg: var(--vz-card-bg-custom);
    --vz-pagination-disabled-border-color: var(--vz-border-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    padding: var(--vz-pagination-padding-y) var(--vz-pagination-padding-x);
    font-size: var(--vz-pagination-font-size);
    color: var(--vz-pagination-color);
    text-decoration: none;
    background-color: var(--vz-pagination-bg);
    border: var(--vz-pagination-border-width) solid var(--vz-pagination-border-color);
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        -webkit-transition: none;
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: var(--vz-pagination-hover-color);
    background-color: var(--vz-pagination-hover-bg);
    border-color: var(--vz-pagination-hover-border-color)
}

.page-link:focus {
    z-index: 3;
    color: var(--vz-pagination-focus-color);
    background-color: var(--vz-pagination-focus-bg);
    outline: 0;
    -webkit-box-shadow: var(--vz-pagination-focus-box-shadow);
    box-shadow: var(--vz-pagination-focus-box-shadow)
}

.active>.page-link,.page-link.active {
    z-index: 3;
    color: var(--vz-pagination-active-color);
    background-color: var(--vz-pagination-active-bg);
    border-color: var(--vz-pagination-active-border-color)
}

.disabled>.page-link,.page-link.disabled {
    color: var(--vz-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--vz-pagination-disabled-bg);
    border-color: var(--vz-pagination-disabled-border-color)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item:first-child .page-link {
    border-top-left-radius: var(--vz-pagination-border-radius);
    border-bottom-left-radius: var(--vz-pagination-border-radius)
}

.page-item:last-child .page-link {
    border-top-right-radius: var(--vz-pagination-border-radius);
    border-bottom-right-radius: var(--vz-pagination-border-radius)
}

.pagination-lg {
    --vz-pagination-padding-x: 1.2rem;
    --vz-pagination-padding-y: 0.6rem;
    --vz-pagination-font-size: 1.015625rem;
    --vz-pagination-border-radius: 0.3rem
}

.pagination-sm {
    --vz-pagination-padding-x: 0.5rem;
    --vz-pagination-padding-y: 0.25rem;
    --vz-pagination-font-size: 0.7109375rem;
    --vz-pagination-border-radius: 0.2rem
}

.badge {
    --vz-badge-padding-x: 0.65em;
    --vz-badge-padding-y: 0.35em;
    --vz-badge-font-size: 0.75em;
    --vz-badge-font-weight: 600;
    --vz-badge-color: #fff;
    --vz-badge-border-radius: 0.25rem;
    display: inline-block;
    padding: var(--vz-badge-padding-y) var(--vz-badge-padding-x);
    font-size: var(--vz-badge-font-size);
    font-weight: var(--vz-badge-font-weight);
    line-height: 1;
    color: var(--vz-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--vz-badge-border-radius)
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    --vz-alert-bg: transparent;
    --vz-alert-padding-x: 1rem;
    --vz-alert-padding-y: 0.8rem;
    --vz-alert-margin-bottom: 1rem;
    --vz-alert-color: inherit;
    --vz-alert-border-color: transparent;
    --vz-alert-border: 1px solid var(--vz-alert-border-color);
    --vz-alert-border-radius: 0.25rem;
    position: relative;
    padding: var(--vz-alert-padding-y) var(--vz-alert-padding-x);
    margin-bottom: var(--vz-alert-margin-bottom);
    color: var(--vz-alert-color);
    background-color: var(--vz-alert-bg);
    border: var(--vz-alert-border);
    border-radius: var(--vz-alert-border-radius)
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 600
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem 1rem
}

.alert-primary {
    --vz-alert-color: #303d67;
    --vz-alert-bg: #e2e5ed;
    --vz-alert-border-color: #c6cbdc
}

.alert-primary .alert-link {
    color: #263152
}

.alert-secondary {
    --vz-alert-color: #2859b5;
    --vz-alert-bg: #e1ebfd;
    --vz-alert-border-color: #c2d6fb
}

.alert-secondary .alert-link {
    color: #204791
}

.alert-success {
    --vz-alert-color: #088675;
    --vz-alert-bg: #daf4f0;
    --vz-alert-border-color: #b6e8e1
}

.alert-success .alert-link {
    color: #066b5e
}

.alert-info {
    --vz-alert-color: #1f75a4;
    --vz-alert-bg: #dff0fa;
    --vz-alert-border-color: #bfe1f4
}

.alert-info .alert-link {
    color: #195e83
}

.alert-warning {
    --vz-alert-color: #b98a38;
    --vz-alert-bg: #fef4e4;
    --vz-alert-border-color: #fdeac9
}

.alert-warning .alert-link {
    color: #946e2d
}

.alert-danger {
    --vz-alert-color: #b44c36;
    --vz-alert-bg: #fde8e4;
    --vz-alert-border-color: #fbd1c8
}

.alert-danger .alert-link {
    color: #903d2b
}

.alert-light {
    --vz-alert-color: #b6b9bb;
    --vz-alert-bg: #fdfefe;
    --vz-alert-border-color: #fbfcfd
}

.alert-light .alert-link {
    color: #929496
}

.alert-dark {
    --vz-alert-color: #191c1f;
    --vz-alert-bg: #dededf;
    --vz-alert-border-color: #bcbebf
}

.alert-dark .alert-link {
    color: #141619
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: .625rem
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: .625rem
    }
}

.progress {
    --vz-progress-height: 0.625rem;
    --vz-progress-font-size: 0.609375rem;
    --vz-progress-bg: #eff2f7;
    --vz-progress-border-radius: 0.25rem;
    --vz-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --vz-progress-bar-color: #fff;
    --vz-progress-bar-bg: #405189;
    --vz-progress-bar-transition: width 0.6s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: var(--vz-progress-height);
    overflow: hidden;
    font-size: var(--vz-progress-font-size);
    background-color: var(--vz-progress-bg);
    border-radius: var(--vz-progress-border-radius)
}

.progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: var(--vz-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--vz-progress-bar-bg);
    -webkit-transition: var(--vz-progress-bar-transition);
    transition: var(--vz-progress-bar-transition)
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        -webkit-transition: none;
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: var(--vz-progress-height) var(--vz-progress-height)
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.list-group {
    --vz-list-group-color: var(--vz-body-color);
    --vz-list-group-bg: var(--vz-card-bg-custom);
    --vz-list-group-border-color: var(--vz-border-color);
    --vz-list-group-border-width: 1px;
    --vz-list-group-border-radius: 0.25rem;
    --vz-list-group-item-padding-x: 1rem;
    --vz-list-group-item-padding-y: 0.7rem;
    --vz-list-group-action-color: var(--vz-body-color);
    --vz-list-group-action-hover-color: var(--vz-body-color);
    --vz-list-group-action-hover-bg: var(--vz-list-group-hover-bg);
    --vz-list-group-action-active-color: var(--vz-body-color);
    --vz-list-group-action-active-bg: var(--vz-list-group-hover-bg);
    --vz-list-group-disabled-color: #878a99;
    --vz-list-group-disabled-bg: var(--vz-card-bg-custom);
    --vz-list-group-active-color: #fff;
    --vz-list-group-active-bg: #405189;
    --vz-list-group-active-border-color: #405189;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--vz-list-group-border-radius)
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>.list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: var(--vz-list-group-action-color);
    text-align: inherit
}

.list-group-item-action:focus,.list-group-item-action:hover {
    z-index: 1;
    color: var(--vz-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--vz-list-group-action-hover-bg)
}

.list-group-item-action:active {
    color: var(--vz-list-group-action-active-color);
    background-color: var(--vz-list-group-action-active-bg)
}

.list-group-item {
    position: relative;
    display: block;
    padding: var(--vz-list-group-item-padding-y) var(--vz-list-group-item-padding-x);
    color: var(--vz-list-group-color);
    text-decoration: none;
    background-color: var(--vz-list-group-bg);
    border: var(--vz-list-group-border-width) solid var(--vz-list-group-border-color)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,.list-group-item:disabled {
    color: var(--vz-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--vz-list-group-disabled-bg)
}

.list-group-item.active {
    z-index: 2;
    color: var(--vz-list-group-active-color);
    background-color: var(--vz-list-group-active-bg);
    border-color: var(--vz-list-group-active-border-color)
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: calc(-1 * var(--vz-list-group-border-width));
    border-top-width: var(--vz-list-group-border-width)
}

.list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--vz-list-group-border-radius);
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--vz-list-group-border-radius);
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: var(--vz-list-group-border-width);
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--vz-list-group-border-width));
    border-left-width: var(--vz-list-group-border-width)
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--vz-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--vz-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: var(--vz-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--vz-list-group-border-width));
        border-left-width: var(--vz-list-group-border-width)
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--vz-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--vz-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: var(--vz-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--vz-list-group-border-width));
        border-left-width: var(--vz-list-group-border-width)
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--vz-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--vz-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: var(--vz-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--vz-list-group-border-width));
        border-left-width: var(--vz-list-group-border-width)
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--vz-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--vz-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: var(--vz-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--vz-list-group-border-width));
        border-left-width: var(--vz-list-group-border-width)
    }
}

@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        -webkit-box-orient:horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
        border-bottom-left-radius: var(--vz-list-group-border-radius);
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
        border-top-right-radius: var(--vz-list-group-border-radius);
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: var(--vz-list-group-border-width);
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: calc(-1 * var(--vz-list-group-border-width));
        border-left-width: var(--vz-list-group-border-width)
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 var(--vz-list-group-border-width)
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #263152;
    background-color: #d9dce7
}

.list-group-item-primary.list-group-item-action:focus,.list-group-item-primary.list-group-item-action:hover {
    color: #263152;
    background-color: #c3c6d0
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #263152;
    border-color: #263152
}

.list-group-item-secondary {
    color: #204791;
    background-color: #d7e4fc
}

.list-group-item-secondary.list-group-item-action:focus,.list-group-item-secondary.list-group-item-action:hover {
    color: #204791;
    background-color: #c2cde3
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #204791;
    border-color: #204791
}

.list-group-item-success {
    color: #066b5e;
    background-color: #cef0eb
}

.list-group-item-success.list-group-item-action:focus,.list-group-item-success.list-group-item-action:hover {
    color: #066b5e;
    background-color: #b9d8d4
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #066b5e;
    border-color: #066b5e
}

.list-group-item-info {
    color: #195e83;
    background-color: #d4ebf8
}

.list-group-item-info.list-group-item-action:focus,.list-group-item-info.list-group-item-action:hover {
    color: #195e83;
    background-color: #bfd4df
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #195e83;
    border-color: #195e83
}

.list-group-item-warning {
    color: #946e2d;
    background-color: #fdf1db
}

.list-group-item-warning.list-group-item-action:focus,.list-group-item-warning.list-group-item-action:hover {
    color: #946e2d;
    background-color: #e4d9c5
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #946e2d;
    border-color: #946e2d
}

.list-group-item-danger {
    color: #903d2b;
    background-color: #fce0da
}

.list-group-item-danger.list-group-item-action:focus,.list-group-item-danger.list-group-item-action:hover {
    color: #903d2b;
    background-color: #e3cac4
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #903d2b;
    border-color: #903d2b
}

.list-group-item-light {
    color: #929495;
    background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:focus,.list-group-item-light.list-group-item-action:hover {
    color: #929495;
    background-color: #e4e4e5
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #929495;
    border-color: #929495
}

.list-group-item-dark {
    color: #141619;
    background-color: #d3d3d4
}

.list-group-item-dark.list-group-item-action:focus,.list-group-item-dark.list-group-item-action:hover {
    color: #141619;
    background-color: #bebebf
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619
}

.btn-close {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(64,81,137,.25);
    box-shadow: 0 0 0 0 rgba(64,81,137,.25);
    opacity: 1
}

.btn-close.disabled,.btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    --vz-toast-zindex: 1090;
    --vz-toast-padding-x: 0.75rem;
    --vz-toast-padding-y: 0.5rem;
    --vz-toast-spacing: 1.5rem;
    --vz-toast-max-width: 350px;
    --vz-toast-font-size: 0.875rem;
    --vz-toast-bg: rgba(255, 255, 255, 0.85);
    --vz-toast-border-width: 1px;
    --vz-toast-border-color: rgba(0, 0, 0, 0.1);
    --vz-toast-border-radius: 0.25rem;
    --vz-toast-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    --vz-toast-header-color: #878a99;
    --vz-toast-header-bg: rgba(255, 255, 255, 0.85);
    --vz-toast-header-border-color: rgba(0, 0, 0, 0.05);
    width: var(--vz-toast-max-width);
    max-width: 100%;
    font-size: var(--vz-toast-font-size);
    color: var(--vz-toast-color);
    pointer-events: auto;
    background-color: var(--vz-toast-bg);
    background-clip: padding-box;
    border: var(--vz-toast-border-width) solid var(--vz-toast-border-color);
    -webkit-box-shadow: var(--vz-toast-box-shadow);
    box-shadow: var(--vz-toast-box-shadow);
    border-radius: var(--vz-toast-border-radius)
}

.toast.showing {
    opacity: 0
}

.toast:not(.show) {
    display: none
}

.toast-container {
    --vz-toast-zindex: 1090;
    position: absolute;
    z-index: var(--vz-toast-zindex);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: var(--vz-toast-spacing)
}

.toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: var(--vz-toast-padding-y) var(--vz-toast-padding-x);
    color: var(--vz-toast-header-color);
    background-color: var(--vz-toast-header-bg);
    background-clip: padding-box;
    border-bottom: var(--vz-toast-border-width) solid var(--vz-toast-header-border-color);
    border-top-left-radius: calc(var(--vz-toast-border-radius) - var(--vz-toast-border-width));
    border-top-right-radius: calc(var(--vz-toast-border-radius) - var(--vz-toast-border-width))
}

.toast-header .btn-close {
    margin-right: calc(-.5 * var(--vz-toast-padding-x));
    margin-left: var(--vz-toast-padding-x)
}

.toast-body {
    padding: var(--vz-toast-padding-x);
    word-wrap: break-word
}

.modal {
    --vz-modal-zindex: 1055;
    --vz-modal-width: 500px;
    --vz-modal-padding: 1.25rem;
    --vz-modal-margin: 0.5rem;
    --vz-modal-bg: #fff;
    --vz-modal-border-color: var(--vz-border-color);
    --vz-modal-border-width: 1px;
    --vz-modal-border-radius: 0.3rem;
    --vz-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --vz-modal-inner-border-radius: calc(0.3rem - 1px);
    --vz-modal-header-padding-x: 1.25rem;
    --vz-modal-header-padding-y: 1.25rem;
    --vz-modal-header-padding: 1.25rem 1.25rem;
    --vz-modal-header-border-color: var(--vz-border-color);
    --vz-modal-header-border-width: 0;
    --vz-modal-title-line-height: 1.5;
    --vz-modal-footer-gap: 0.5rem;
    --vz-modal-footer-border-color: var(--vz-border-color);
    --vz-modal-footer-border-width: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--vz-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--vz-modal-margin);
    pointer-events: none
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - var(--vz-modal-margin) * 2)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - var(--vz-modal-margin) * 2)
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    color: var(--vz-modal-color);
    pointer-events: auto;
    background-color: var(--vz-modal-bg);
    background-clip: padding-box;
    border: var(--vz-modal-border-width) solid var(--vz-modal-border-color);
    border-radius: var(--vz-modal-border-radius);
    outline: 0
}

.modal-backdrop {
    --vz-backdrop-zindex: 1050;
    --vz-backdrop-bg: #000;
    --vz-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--vz-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--vz-backdrop-bg)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: var(--vz-backdrop-opacity)
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--vz-modal-header-padding);
    border-bottom: var(--vz-modal-header-border-width) solid var(--vz-modal-header-border-color);
    border-top-left-radius: var(--vz-modal-inner-border-radius);
    border-top-right-radius: var(--vz-modal-inner-border-radius)
}

.modal-header .btn-close {
    padding: calc(var(--vz-modal-header-padding-y) * .5) calc(var(--vz-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--vz-modal-header-padding-y)) calc(-.5 * var(--vz-modal-header-padding-x)) calc(-.5 * var(--vz-modal-header-padding-y)) auto
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--vz-modal-title-line-height)
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--vz-modal-padding)
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: calc(var(--vz-modal-padding) - var(--vz-modal-footer-gap) * .5);
    background-color: var(--vz-modal-footer-bg);
    border-top: var(--vz-modal-footer-border-width) solid var(--vz-modal-footer-border-color);
    border-bottom-right-radius: var(--vz-modal-inner-border-radius);
    border-bottom-left-radius: var(--vz-modal-inner-border-radius)
}

.modal-footer>* {
    margin: calc(var(--vz-modal-footer-gap) * .5)
}

@media (min-width: 576px) {
    .modal {
        --vz-modal-margin:1.75rem;
        --vz-modal-box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15)
    }

    .modal-dialog {
        max-width: var(--vz-modal-width);
        margin-right: auto;
        margin-left: auto
    }

    .modal-sm {
        --vz-modal-width: 300px
    }
}

@media (min-width: 992px) {
    .modal-lg,.modal-xl {
        --vz-modal-width:800px
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        --vz-modal-width:1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-footer,.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-footer,.modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-footer,.modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-footer,.modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-footer,.modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-footer,.modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }
}

.tooltip {
    --vz-tooltip-zindex: 1080;
    --vz-tooltip-max-width: 200px;
    --vz-tooltip-padding-x: 0.7rem;
    --vz-tooltip-padding-y: 0.4rem;
    --vz-tooltip-margin: 0;
    --vz-tooltip-font-size: 0.7109375rem;
    --vz-tooltip-color: #fff;
    --vz-tooltip-bg: #000;
    --vz-tooltip-border-radius: 0.2rem;
    --vz-tooltip-opacity: 0.9;
    --vz-tooltip-arrow-width: 0.8rem;
    --vz-tooltip-arrow-height: 0.4rem;
    z-index: var(--vz-tooltip-zindex);
    display: block;
    padding: var(--vz-tooltip-arrow-height);
    margin: var(--vz-tooltip-margin);
    font-family: var(--vz-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--vz-tooltip-font-size);
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: var(--vz-tooltip-opacity)
}

.tooltip .tooltip-arrow {
    display: block;
    width: var(--vz-tooltip-arrow-width);
    height: var(--vz-tooltip-arrow-height)
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,.bs-tooltip-top .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: var(--vz-tooltip-arrow-height) calc(var(--vz-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--vz-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,.bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: var(--vz-tooltip-arrow-height);
    height: var(--vz-tooltip-arrow-width)
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--vz-tooltip-arrow-width) * .5) var(--vz-tooltip-arrow-height) calc(var(--vz-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--vz-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,.bs-tooltip-bottom .tooltip-arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--vz-tooltip-arrow-width) * .5) var(--vz-tooltip-arrow-height);
    border-bottom-color: var(--vz-tooltip-bg)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,.bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: var(--vz-tooltip-arrow-height);
    height: var(--vz-tooltip-arrow-width)
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--vz-tooltip-arrow-width) * .5) 0 calc(var(--vz-tooltip-arrow-width) * .5) var(--vz-tooltip-arrow-height);
    border-left-color: var(--vz-tooltip-bg)
}

.tooltip-inner {
    max-width: var(--vz-tooltip-max-width);
    padding: var(--vz-tooltip-padding-y) var(--vz-tooltip-padding-x);
    color: var(--vz-tooltip-color);
    text-align: center;
    background-color: var(--vz-tooltip-bg);
    border-radius: var(--vz-tooltip-border-radius)
}

.popover {
    --vz-popover-zindex: 1070;
    --vz-popover-max-width: 276px;
    --vz-popover-font-size: 0.8125rem;
    --vz-popover-bg: #fff;
    --vz-popover-border-width: 1px;
    --vz-popover-border-color: var(--vz-border-color);
    --vz-popover-border-radius: 0.25rem;
    --vz-popover-inner-border-radius: calc(0.25rem - 1px);
    --vz-popover-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    --vz-popover-header-padding-x: 1rem;
    --vz-popover-header-padding-y: 0.7rem;
    --vz-popover-header-font-size: 0.8125rem;
    --vz-popover-header-bg: var(--vz-popover-bg);
    --vz-popover-body-padding-x: 1rem;
    --vz-popover-body-padding-y: 1rem;
    --vz-popover-body-color: var(--vz-body-color);
    --vz-popover-arrow-width: 1rem;
    --vz-popover-arrow-height: 0.5rem;
    --vz-popover-arrow-border: var(--vz-popover-border-color);
    z-index: var(--vz-popover-zindex);
    display: block;
    max-width: var(--vz-popover-max-width);
    font-family: var(--vz-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: var(--vz-popover-font-size);
    word-wrap: break-word;
    background-color: var(--vz-popover-bg);
    background-clip: padding-box;
    border: var(--vz-popover-border-width) solid var(--vz-popover-border-color);
    border-radius: var(--vz-popover-border-radius)
}

.popover .popover-arrow {
    display: block;
    width: var(--vz-popover-arrow-width);
    height: var(--vz-popover-arrow-height)
}

.popover .popover-arrow::after,.popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,.bs-popover-top>.popover-arrow {
    bottom: calc(-1 * (var(--vz-popover-arrow-height)) - var(--vz-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,.bs-popover-top>.popover-arrow::after,.bs-popover-top>.popover-arrow::before {
    border-width: var(--vz-popover-arrow-height) calc(var(--vz-popover-arrow-width) * .5) 0
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,.bs-popover-top>.popover-arrow::before {
    bottom: 0;
    border-top-color: var(--vz-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,.bs-popover-top>.popover-arrow::after {
    bottom: var(--vz-popover-border-width);
    border-top-color: var(--vz-popover-bg)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,.bs-popover-end>.popover-arrow {
    left: calc(-1 * (var(--vz-popover-arrow-height)) - var(--vz-popover-border-width));
    width: var(--vz-popover-arrow-height);
    height: var(--vz-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,.bs-popover-end>.popover-arrow::after,.bs-popover-end>.popover-arrow::before {
    border-width: calc(var(--vz-popover-arrow-width) * .5) var(--vz-popover-arrow-height) calc(var(--vz-popover-arrow-width) * .5) 0
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,.bs-popover-end>.popover-arrow::before {
    left: 0;
    border-right-color: var(--vz-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,.bs-popover-end>.popover-arrow::after {
    left: var(--vz-popover-border-width);
    border-right-color: var(--vz-popover-bg)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,.bs-popover-bottom>.popover-arrow {
    top: calc(-1 * (var(--vz-popover-arrow-height)) - var(--vz-popover-border-width))
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,.bs-popover-bottom>.popover-arrow::after,.bs-popover-bottom>.popover-arrow::before {
    border-width: 0 calc(var(--vz-popover-arrow-width) * .5) var(--vz-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,.bs-popover-bottom>.popover-arrow::before {
    top: 0;
    border-bottom-color: var(--vz-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,.bs-popover-bottom>.popover-arrow::after {
    top: var(--vz-popover-border-width);
    border-bottom-color: var(--vz-popover-bg)
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--vz-popover-arrow-width);
    margin-left: calc(-.5 * var(--vz-popover-arrow-width));
    content: "";
    border-bottom: var(--vz-popover-border-width) solid var(--vz-popover-header-bg)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,.bs-popover-start>.popover-arrow {
    right: calc(-1 * (var(--vz-popover-arrow-height)) - var(--vz-popover-border-width));
    width: var(--vz-popover-arrow-height);
    height: var(--vz-popover-arrow-width)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,.bs-popover-start>.popover-arrow::after,.bs-popover-start>.popover-arrow::before {
    border-width: calc(var(--vz-popover-arrow-width) * .5) 0 calc(var(--vz-popover-arrow-width) * .5) var(--vz-popover-arrow-height)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,.bs-popover-start>.popover-arrow::before {
    right: 0;
    border-left-color: var(--vz-popover-arrow-border)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,.bs-popover-start>.popover-arrow::after {
    right: var(--vz-popover-border-width);
    border-left-color: var(--vz-popover-bg)
}

.popover-header {
    padding: var(--vz-popover-header-padding-y) var(--vz-popover-header-padding-x);
    margin-bottom: 0;
    font-size: var(--vz-popover-header-font-size);
    color: var(--vz-popover-header-color);
    background-color: var(--vz-popover-header-bg);
    border-bottom: var(--vz-popover-border-width) solid var(--vz-popover-border-color);
    border-top-left-radius: var(--vz-popover-inner-border-radius);
    border-top-right-radius: var(--vz-popover-inner-border-radius)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: var(--vz-popover-body-padding-y) var(--vz-popover-body-padding-x);
    color: var(--vz-popover-body-color)
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-item-next,.carousel-item-prev,.carousel-item.active {
    display: block
}

.active.carousel-item-end,.carousel-item-next:not(.carousel-item-start) {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.active.carousel-item-start,.carousel-item-prev:not(.carousel-item-end) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,.carousel-fade .carousel-item-prev.carousel-item-end,.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s .6s;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-control-next,.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    -webkit-transition: opacity .15s ease;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-control-next,.carousel-control-prev {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-control-next:focus,.carousel-control-next:hover,.carousel-control-prev:focus,.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity .6s ease;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        -webkit-transition: none;
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,.carousel-dark .carousel-control-prev-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

.spinner-border,.spinner-grow {
    display: inline-block;
    width: var(--vz-spinner-width);
    height: var(--vz-spinner-height);
    vertical-align: var(--vz-spinner-vertical-align);
    border-radius: 50%;
    -webkit-animation: var(--vz-spinner-animation-speed) linear infinite var(--vz-spinner-animation-name);
    animation: var(--vz-spinner-animation-speed) linear infinite var(--vz-spinner-animation-name)
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.spinner-border {
    --vz-spinner-width: 2rem;
    --vz-spinner-height: 2rem;
    --vz-spinner-vertical-align: -0.125em;
    --vz-spinner-border-width: 0.25em;
    --vz-spinner-animation-speed: 0.75s;
    --vz-spinner-animation-name: spinner-border;
    border: var(--vz-spinner-border-width) solid currentcolor;
    border-right-color: transparent
}

.spinner-border-sm {
    --vz-spinner-width: 1rem;
    --vz-spinner-height: 1rem;
    --vz-spinner-border-width: 0.2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.spinner-grow {
    --vz-spinner-width: 2rem;
    --vz-spinner-height: 2rem;
    --vz-spinner-vertical-align: -0.125em;
    --vz-spinner-animation-speed: 0.75s;
    --vz-spinner-animation-name: spinner-grow;
    background-color: currentcolor;
    opacity: 0
}

.spinner-grow-sm {
    --vz-spinner-width: 1rem;
    --vz-spinner-height: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .spinner-border,.spinner-grow {
        --vz-spinner-animation-speed: 1.5s
    }
}

.offcanvas,.offcanvas-lg,.offcanvas-md,.offcanvas-sm,.offcanvas-xl,.offcanvas-xxl {
    --vz-offcanvas-zindex: 1045;
    --vz-offcanvas-width: 400px;
    --vz-offcanvas-height: 30vh;
    --vz-offcanvas-padding-x: 1.25rem;
    --vz-offcanvas-padding-y: 1.25rem;
    --vz-offcanvas-bg: #fff;
    --vz-offcanvas-border-width: 1px;
    --vz-offcanvas-border-color: var(--vz-border-color);
    --vz-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)
}

@media (max-width: 575.98px) {
    .offcanvas-sm {
        position:fixed;
        bottom: 0;
        z-index: var(--vz-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--vz-offcanvas-color);
        visibility: hidden;
        background-color: var(--vz-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
    }
}

@media (max-width: 575.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-sm {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
        top:0;
        left: 0;
        width: var(--vz-offcanvas-width);
        border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-end {
        top:0;
        right: 0;
        width: var(--vz-offcanvas-width);
        border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.show:not(.hiding),.offcanvas-sm.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 575.98px) {
    .offcanvas-sm.hiding,.offcanvas-sm.show,.offcanvas-sm.showing {
        visibility:visible
    }
}

@media (min-width: 576px) {
    .offcanvas-sm {
        --vz-offcanvas-height:auto;
        --vz-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-sm .offcanvas-header {
        display: none
    }

    .offcanvas-sm .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md {
        position:fixed;
        bottom: 0;
        z-index: var(--vz-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--vz-offcanvas-color);
        visibility: hidden;
        background-color: var(--vz-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
    }
}

@media (max-width: 767.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-md {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
        top:0;
        left: 0;
        width: var(--vz-offcanvas-width);
        border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-end {
        top:0;
        right: 0;
        width: var(--vz-offcanvas-width);
        border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.show:not(.hiding),.offcanvas-md.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 767.98px) {
    .offcanvas-md.hiding,.offcanvas-md.show,.offcanvas-md.showing {
        visibility:visible
    }
}

@media (min-width: 768px) {
    .offcanvas-md {
        --vz-offcanvas-height:auto;
        --vz-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-md .offcanvas-header {
        display: none
    }

    .offcanvas-md .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg {
        position:fixed;
        bottom: 0;
        z-index: var(--vz-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--vz-offcanvas-color);
        visibility: hidden;
        background-color: var(--vz-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
    }
}

@media (max-width: 991.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-lg {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
        top:0;
        left: 0;
        width: var(--vz-offcanvas-width);
        border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-end {
        top:0;
        right: 0;
        width: var(--vz-offcanvas-width);
        border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.show:not(.hiding),.offcanvas-lg.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 991.98px) {
    .offcanvas-lg.hiding,.offcanvas-lg.show,.offcanvas-lg.showing {
        visibility:visible
    }
}

@media (min-width: 992px) {
    .offcanvas-lg {
        --vz-offcanvas-height:auto;
        --vz-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-lg .offcanvas-header {
        display: none
    }

    .offcanvas-lg .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl {
        position:fixed;
        bottom: 0;
        z-index: var(--vz-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--vz-offcanvas-color);
        visibility: hidden;
        background-color: var(--vz-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
    }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xl {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
        top:0;
        left: 0;
        width: var(--vz-offcanvas-width);
        border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-end {
        top:0;
        right: 0;
        width: var(--vz-offcanvas-width);
        border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.show:not(.hiding),.offcanvas-xl.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 1199.98px) {
    .offcanvas-xl.hiding,.offcanvas-xl.show,.offcanvas-xl.showing {
        visibility:visible
    }
}

@media (min-width: 1200px) {
    .offcanvas-xl {
        --vz-offcanvas-height:auto;
        --vz-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-xl .offcanvas-header {
        display: none
    }

    .offcanvas-xl .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl {
        position:fixed;
        bottom: 0;
        z-index: var(--vz-offcanvas-zindex);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 100%;
        color: var(--vz-offcanvas-color);
        visibility: hidden;
        background-color: var(--vz-offcanvas-bg);
        background-clip: padding-box;
        outline: 0;
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
    }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion:reduce) {
    .offcanvas-xxl {
        -webkit-transition:none;
        transition: none
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
        top:0;
        left: 0;
        width: var(--vz-offcanvas-width);
        border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-end {
        top:0;
        right: 0;
        width: var(--vz-offcanvas-width);
        border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-top {
        top:0;
        right: 0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-bottom {
        right:0;
        left: 0;
        height: var(--vz-offcanvas-height);
        max-height: 100%;
        border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.show:not(.hiding),.offcanvas-xxl.showing {
        -webkit-transform:none;
        transform: none
    }
}

@media (max-width: 1399.98px) {
    .offcanvas-xxl.hiding,.offcanvas-xxl.show,.offcanvas-xxl.showing {
        visibility:visible
    }
}

@media (min-width: 1400px) {
    .offcanvas-xxl {
        --vz-offcanvas-height:auto;
        --vz-offcanvas-border-width: 0;
        background-color: transparent!important
    }

    .offcanvas-xxl .offcanvas-header {
        display: none
    }

    .offcanvas-xxl .offcanvas-body {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent!important
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: var(--vz-offcanvas-zindex);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    color: var(--vz-offcanvas-color);
    visibility: hidden;
    background-color: var(--vz-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        -webkit-transition: none;
        transition: none
    }
}

.offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--vz-offcanvas-width);
    border-right: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--vz-offcanvas-width);
    border-left: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--vz-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--vz-offcanvas-height);
    max-height: 100%;
    border-top: var(--vz-offcanvas-border-width) solid var(--vz-offcanvas-border-color);
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.offcanvas.show:not(.hiding),.offcanvas.showing {
    -webkit-transform: none;
    transform: none
}

.offcanvas.hiding,.offcanvas.show,.offcanvas.showing {
    visibility: visible
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.offcanvas-backdrop.fade {
    opacity: 0
}

.offcanvas-backdrop.show {
    opacity: .5
}

.offcanvas-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: var(--vz-offcanvas-padding-y) var(--vz-offcanvas-padding-x)
}

.offcanvas-header .btn-close {
    padding: calc(var(--vz-offcanvas-padding-y) * .5) calc(var(--vz-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--vz-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--vz-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--vz-offcanvas-padding-y))
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5
}

.offcanvas-body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: var(--vz-offcanvas-padding-y) var(--vz-offcanvas-padding-x);
    overflow-y: auto
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: .5
}

.placeholder.btn::before {
    display: inline-block;
    content: ""
}

.placeholder-xs {
    min-height: .6em
}

.placeholder-sm {
    min-height: .8em
}

.placeholder-lg {
    min-height: 1.2em
}

.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: .2
    }
}

.placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,.8) 75%,#000 95%);
    mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,.8) 75%,#000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite
}

@-webkit-keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

@keyframes placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.text-bg-primary {
    color: #fff!important;
    background-color: RGBA(64,81,137,var(--vz-bg-opacity,1))!important
}

.text-bg-secondary {
    color: #fff!important;
    background-color: RGBA(53,119,241,var(--vz-bg-opacity,1))!important
}

.text-bg-success {
    color: #fff!important;
    background-color: RGBA(10,179,156,var(--vz-bg-opacity,1))!important
}

.text-bg-info {
    color: #fff!important;
    background-color: RGBA(41,156,219,var(--vz-bg-opacity,1))!important
}

.text-bg-warning {
    color: #fff!important;
    background-color: RGBA(247,184,75,var(--vz-bg-opacity,1))!important
}

.text-bg-danger {
    color: #fff!important;
    background-color: RGBA(240,101,72,var(--vz-bg-opacity,1))!important
}

.text-bg-light {
    color: #000!important;
    background-color: RGBA(243,246,249,var(--vz-bg-opacity,1))!important
}

.text-bg-dark {
    color: #fff!important;
    background-color: RGBA(33,37,41,var(--vz-bg-opacity,1))!important
}

.link-primary {
    color: #405189!important
}

.link-primary:focus,.link-primary:hover {
    color: #33416e!important
}

.link-secondary {
    color: #3577f1!important
}

.link-secondary:focus,.link-secondary:hover {
    color: #2a5fc1!important
}

.link-success {
    color: #0ab39c!important
}

.link-success:focus,.link-success:hover {
    color: #088f7d!important
}

.link-info {
    color: #299cdb!important
}

.link-info:focus,.link-info:hover {
    color: #217daf!important
}

.link-warning {
    color: #f7b84b!important
}

.link-warning:focus,.link-warning:hover {
    color: #c6933c!important
}

.link-danger {
    color: #f06548!important
}

.link-danger:focus,.link-danger:hover {
    color: #c0513a!important
}

.link-light {
    color: #f3f6f9!important
}

.link-light:focus,.link-light:hover {
    color: #f5f8fa!important
}

.link-dark {
    color: #212529!important
}

.link-dark:focus,.link-dark:hover {
    color: #1a1e21!important
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--vz-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --vz-aspect-ratio: 100%
}

.ratio-4x3 {
    --vz-aspect-ratio: 75%
}

.ratio-16x9 {
    --vz-aspect-ratio: 56.25%
}

.ratio-21x9 {
    --vz-aspect-ratio: 42.8571428571%
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020
}

@media (min-width: 576px) {
    .sticky-sm-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-sm-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 768px) {
    .sticky-md-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-md-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 992px) {
    .sticky-lg-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-lg-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 1200px) {
    .sticky-xl-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

@media (min-width: 1400px) {
    .sticky-xxl-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }

    .sticky-xxl-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1020
    }
}

.hstack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    align-self: stretch
}

.vstack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: stretch;
    align-self: stretch
}

.visually-hidden,.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.vr {
    display: inline-block;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: .25
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.float-start {
    float: left!important
}

.float-end {
    float: right!important
}

.float-none {
    float: none!important
}

.opacity-0 {
    opacity: 0!important
}

.opacity-25 {
    opacity: .25!important
}

.opacity-50 {
    opacity: .5!important
}

.opacity-75 {
    opacity: .75!important
}

.opacity-100 {
    opacity: 1!important
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.overflow-visible {
    overflow: visible!important
}

.overflow-scroll {
    overflow: scroll!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-grid {
    display: grid!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important
}

.d-inline-flex {
    display: -webkit-inline-box!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
}

.d-none {
    display: none!important
}

.shadow {
    -webkit-box-shadow: 0 1px 2px rgba(56,65,74,.15)!important;
    box-shadow: 0 1px 2px rgba(56,65,74,.15)!important
}

.shadow-sm {
    -webkit-box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important
}

.shadow-lg {
    -webkit-box-shadow: 0 5px 10px rgba(30,32,37,.12)!important;
    box-shadow: 0 5px 10px rgba(30,32,37,.12)!important
}

.shadow-none {
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: sticky!important
}

.top-0 {
    top: 0!important
}

.top-50 {
    top: 50%!important
}

.top-100 {
    top: 100%!important
}

.bottom-0 {
    bottom: 0!important
}

.bottom-50 {
    bottom: 50%!important
}

.bottom-100 {
    bottom: 100%!important
}

.start-0 {
    left: 0!important
}

.start-50 {
    left: 50%!important
}

.start-100 {
    left: 100%!important
}

.end-0 {
    right: 0!important
}

.end-50 {
    right: 50%!important
}

.end-100 {
    right: 100%!important
}

.translate-middle {
    -webkit-transform: translate(-50%,-50%)!important;
    transform: translate(-50%,-50%)!important
}

.translate-middle-x {
    -webkit-transform: translateX(-50%)!important;
    transform: translateX(-50%)!important
}

.translate-middle-y {
    -webkit-transform: translateY(-50%)!important;
    transform: translateY(-50%)!important
}

.border {
    border: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color)!important
}

.border-0 {
    border: 0!important
}

.border-top {
    border-top: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color)!important
}

.border-top-0 {
    border-top: 0!important
}

.border-end {
    border-right: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color)!important
}

.border-end-0 {
    border-right: 0!important
}

.border-bottom {
    border-bottom: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color)!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-start {
    border-left: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color)!important
}

.border-start-0 {
    border-left: 0!important
}

.border-primary {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-primary-rgb),var(--vz-border-opacity))!important
}

.border-secondary {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-secondary-rgb),var(--vz-border-opacity))!important
}

.border-success {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-success-rgb),var(--vz-border-opacity))!important
}

.border-info {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-info-rgb),var(--vz-border-opacity))!important
}

.border-warning {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-warning-rgb),var(--vz-border-opacity))!important
}

.border-danger {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-danger-rgb),var(--vz-border-opacity))!important
}

.border-light {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-light-rgb),var(--vz-border-opacity))!important
}

.border-dark {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-dark-rgb),var(--vz-border-opacity))!important
}

.border-white {
    --vz-border-opacity: 1;
    border-color: rgba(var(--vz-white-rgb),var(--vz-border-opacity))!important
}

.border-1 {
    --vz-border-width: 1px
}

.border-2 {
    --vz-border-width: 2px
}

.border-3 {
    --vz-border-width: 3px
}

.border-4 {
    --vz-border-width: 4px
}

.border-5 {
    --vz-border-width: 5px
}

.border-opacity-10 {
    --vz-border-opacity: 0.1
}

.border-opacity-25 {
    --vz-border-opacity: 0.25
}

.border-opacity-50 {
    --vz-border-opacity: 0.5
}

.border-opacity-75 {
    --vz-border-opacity: 0.75
}

.border-opacity-100 {
    --vz-border-opacity: 1
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.mw-100 {
    max-width: 100%!important
}

.vw-100 {
    width: 100vw!important
}

.min-vw-100 {
    min-width: 100vw!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.mh-100 {
    max-height: 100%!important
}

.vh-100 {
    height: 100vh!important
}

.min-vh-100 {
    min-height: 100vh!important
}

.flex-fill {
    -webkit-box-flex: 1!important;
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important
}

.flex-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
}

.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
}

.flex-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
}

.flex-grow-0 {
    -webkit-box-flex: 0!important;
    -ms-flex-positive: 0!important;
    flex-grow: 0!important
}

.flex-grow-1 {
    -webkit-box-flex: 1!important;
    -ms-flex-positive: 1!important;
    flex-grow: 1!important
}

.flex-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0!important
}

.flex-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1!important
}

.flex-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
}

.justify-content-start {
    -webkit-box-pack: start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
}

.justify-content-end {
    -webkit-box-pack: end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
}

.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
}

.justify-content-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
}

.justify-content-evenly {
    -webkit-box-pack: space-evenly!important;
    -ms-flex-pack: space-evenly!important;
    justify-content: space-evenly!important
}

.align-items-start {
    -webkit-box-align: start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
}

.align-items-end {
    -webkit-box-align: end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
}

.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
}

.align-items-baseline {
    -webkit-box-align: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
}

.align-items-stretch {
    -webkit-box-align: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
}

.align-content-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
}

.align-content-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
}

.align-content-center {
    -ms-flex-line-pack: center!important;
    align-content: center!important
}

.align-content-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
}

.align-content-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
}

.align-self-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto!important
}

.align-self-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
}

.align-self-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
}

.align-self-center {
    -ms-flex-item-align: center!important;
    align-self: center!important
}

.align-self-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
}

.align-self-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
}

.order-first {
    -webkit-box-ordinal-group: 0!important;
    -ms-flex-order: -1!important;
    order: -1!important
}

.order-0 {
    -webkit-box-ordinal-group: 1!important;
    -ms-flex-order: 0!important;
    order: 0!important
}

.order-1 {
    -webkit-box-ordinal-group: 2!important;
    -ms-flex-order: 1!important;
    order: 1!important
}

.order-2 {
    -webkit-box-ordinal-group: 3!important;
    -ms-flex-order: 2!important;
    order: 2!important
}

.order-3 {
    -webkit-box-ordinal-group: 4!important;
    -ms-flex-order: 3!important;
    order: 3!important
}

.order-4 {
    -webkit-box-ordinal-group: 5!important;
    -ms-flex-order: 4!important;
    order: 4!important
}

.order-5 {
    -webkit-box-ordinal-group: 6!important;
    -ms-flex-order: 5!important;
    order: 5!important
}

.order-last {
    -webkit-box-ordinal-group: 7!important;
    -ms-flex-order: 6!important;
    order: 6!important
}

.m-0 {
    margin: 0!important
}

.m-1 {
    margin: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.m-auto {
    margin: auto!important
}

.mx-0 {
    margin-right: 0!important;
    margin-left: 0!important
}

.mx-1 {
    margin-right: .25rem!important;
    margin-left: .25rem!important
}

.mx-2 {
    margin-right: .5rem!important;
    margin-left: .5rem!important
}

.mx-3 {
    margin-right: 1rem!important;
    margin-left: 1rem!important
}

.mx-4 {
    margin-right: 1.5rem!important;
    margin-left: 1.5rem!important
}

.mx-5 {
    margin-right: 3rem!important;
    margin-left: 3rem!important
}

.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important
}

.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important
}

.my-1 {
    margin-top: .25rem!important;
    margin-bottom: .25rem!important
}

.my-2 {
    margin-top: .5rem!important;
    margin-bottom: .5rem!important
}

.my-3 {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important
}

.my-4 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important
}

.my-5 {
    margin-top: 3rem!important;
    margin-bottom: 3rem!important
}

.my-auto {
    margin-top: auto!important;
    margin-bottom: auto!important
}

.mt-0 {
    margin-top: 0!important
}

.mt-1 {
    margin-top: .25rem!important
}

.mt-2 {
    margin-top: .5rem!important
}

.mt-3 {
    margin-top: 1rem!important
}

.mt-4 {
    margin-top: 1.5rem!important
}

.mt-5 {
    margin-top: 3rem!important
}

.mt-auto {
    margin-top: auto!important
}

.me-0 {
    margin-right: 0!important
}

.me-1 {
    margin-right: .25rem!important
}

.me-2 {
    margin-right: .5rem!important
}

.me-3 {
    margin-right: 1rem!important
}

.me-4 {
    margin-right: 1.5rem!important
}

.me-5 {
    margin-right: 3rem!important
}

.me-auto {
    margin-right: auto!important
}

.mb-0 {
    margin-bottom: 0!important
}

.mb-1 {
    margin-bottom: .25rem!important
}

.mb-2 {
    margin-bottom: .5rem!important
}

.mb-3 {
    margin-bottom: 1rem!important
}

.mb-4 {
    margin-bottom: 1.5rem!important
}

.mb-5 {
    margin-bottom: 3rem!important
}

.mb-auto {
    margin-bottom: auto!important
}

.ms-0 {
    margin-left: 0!important
}

.ms-1 {
    margin-left: .25rem!important
}

.ms-2 {
    margin-left: .5rem!important
}

.ms-3 {
    margin-left: 1rem!important
}

.ms-4 {
    margin-left: 1.5rem!important
}

.ms-5 {
    margin-left: 3rem!important
}

.ms-auto {
    margin-left: auto!important
}

.m-n1 {
    margin: -.25rem!important
}

.m-n2 {
    margin: -.5rem!important
}

.m-n3 {
    margin: -1rem!important
}

.m-n4 {
    margin: -1.5rem!important
}

.m-n5 {
    margin: -3rem!important
}

.mx-n1 {
    margin-right: -.25rem!important;
    margin-left: -.25rem!important
}

.mx-n2 {
    margin-right: -.5rem!important;
    margin-left: -.5rem!important
}

.mx-n3 {
    margin-right: -1rem!important;
    margin-left: -1rem!important
}

.mx-n4 {
    margin-right: -1.5rem!important;
    margin-left: -1.5rem!important
}

.mx-n5 {
    margin-right: -3rem!important;
    margin-left: -3rem!important
}

.my-n1 {
    margin-top: -.25rem!important;
    margin-bottom: -.25rem!important
}

.my-n2 {
    margin-top: -.5rem!important;
    margin-bottom: -.5rem!important
}

.my-n3 {
    margin-top: -1rem!important;
    margin-bottom: -1rem!important
}

.my-n4 {
    margin-top: -1.5rem!important;
    margin-bottom: -1.5rem!important
}

.my-n5 {
    margin-top: -3rem!important;
    margin-bottom: -3rem!important
}

.mt-n1 {
    margin-top: -.25rem!important
}

.mt-n2 {
    margin-top: -.5rem!important
}

.mt-n3 {
    margin-top: -1rem!important
}

.mt-n4 {
    margin-top: -1.5rem!important
}

.mt-n5 {
    margin-top: -3rem!important
}

.me-n1 {
    margin-right: -.25rem!important
}

.me-n2 {
    margin-right: -.5rem!important
}

.me-n3 {
    margin-right: -1rem!important
}

.me-n4 {
    margin-right: -1.5rem!important
}

.me-n5 {
    margin-right: -3rem!important
}

.mb-n1 {
    margin-bottom: -.25rem!important
}

.mb-n2 {
    margin-bottom: -.5rem!important
}

.mb-n3 {
    margin-bottom: -1rem!important
}

.mb-n4 {
    margin-bottom: -1.5rem!important
}

.mb-n5 {
    margin-bottom: -3rem!important
}

.ms-n1 {
    margin-left: -.25rem!important
}

.ms-n2 {
    margin-left: -.5rem!important
}

.ms-n3 {
    margin-left: -1rem!important
}

.ms-n4 {
    margin-left: -1.5rem!important
}

.ms-n5 {
    margin-left: -3rem!important
}

.p-0 {
    padding: 0!important
}

.p-1 {
    padding: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.px-0 {
    padding-right: 0!important;
    padding-left: 0!important
}

.px-1 {
    padding-right: .25rem!important;
    padding-left: .25rem!important
}

.px-2 {
    padding-right: .5rem!important;
    padding-left: .5rem!important
}

.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important
}

.px-4 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important
}

.px-5 {
    padding-right: 3rem!important;
    padding-left: 3rem!important
}

.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.py-1 {
    padding-top: .25rem!important;
    padding-bottom: .25rem!important
}

.py-2 {
    padding-top: .5rem!important;
    padding-bottom: .5rem!important
}

.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important
}

.py-4 {
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important
}

.py-5 {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important
}

.pt-0 {
    padding-top: 0!important
}

.pt-1 {
    padding-top: .25rem!important
}

.pt-2 {
    padding-top: .5rem!important
}

.pt-3 {
    padding-top: 1rem!important
}

.pt-4 {
    padding-top: 1.5rem!important
}

.pt-5 {
    padding-top: 3rem!important
}

.pe-0 {
    padding-right: 0!important
}

.pe-1 {
    padding-right: .25rem!important
}

.pe-2 {
    padding-right: .5rem!important
}

.pe-3 {
    padding-right: 1rem!important
}

.pe-4 {
    padding-right: 1.5rem!important
}

.pe-5 {
    padding-right: 3rem!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pb-1 {
    padding-bottom: .25rem!important
}

.pb-2 {
    padding-bottom: .5rem!important
}

.pb-3 {
    padding-bottom: 1rem!important
}

.pb-4 {
    padding-bottom: 1.5rem!important
}

.pb-5 {
    padding-bottom: 3rem!important
}

.ps-0 {
    padding-left: 0!important
}

.ps-1 {
    padding-left: .25rem!important
}

.ps-2 {
    padding-left: .5rem!important
}

.ps-3 {
    padding-left: 1rem!important
}

.ps-4 {
    padding-left: 1.5rem!important
}

.ps-5 {
    padding-left: 3rem!important
}

.gap-0 {
    gap: 0!important
}

.gap-1 {
    gap: .25rem!important
}

.gap-2 {
    gap: .5rem!important
}

.gap-3 {
    gap: 1rem!important
}

.gap-4 {
    gap: 1.5rem!important
}

.gap-5 {
    gap: 3rem!important
}

.font-monospace {
    font-family: var(--vz-font-monospace)!important
}

.fs-1 {
    font-size: calc(1.328125rem + .9375vw)!important
}

.fs-2 {
    font-size: calc(1.2875rem + .45vw)!important
}

.fs-3 {
    font-size: calc(1.2671875rem + .20625vw)!important
}

.fs-4 {
    font-size: 1.21875rem!important
}

.fs-5 {
    font-size: 1.015625rem!important
}

.fs-6 {
    font-size: .8125rem!important
}

.fst-italic {
    font-style: italic!important
}

.fst-normal {
    font-style: normal!important
}

.fw-light {
    font-weight: 300!important
}

.fw-lighter {
    font-weight: lighter!important
}

.fw-normal {
    font-weight: 400!important
}

.fw-bold {
    font-weight: 700!important
}

.fw-semibold {
    font-weight: 600!important
}

.fw-bolder {
    font-weight: bolder!important
}

.lh-1 {
    line-height: 1!important
}

.lh-sm {
    line-height: 1.25!important
}

.lh-base {
    line-height: 1.5!important
}

.lh-lg {
    line-height: 2!important
}

.text-start {
    text-align: left!important
}

.text-end {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

.text-decoration-none {
    text-decoration: none!important
}

.text-decoration-underline {
    text-decoration: underline!important
}

.text-decoration-line-through {
    text-decoration: line-through!important
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-break {
    word-wrap: break-word!important;
    word-break: break-word!important
}

.text-primary {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-primary-rgb),var(--vz-text-opacity))!important
}

.text-secondary {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-secondary-rgb),var(--vz-text-opacity))!important
}

.text-success {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-success-rgb),var(--vz-text-opacity))!important
}

.text-info {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-info-rgb),var(--vz-text-opacity))!important
}

.text-warning {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-warning-rgb),var(--vz-text-opacity))!important
}

.text-danger {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-danger-rgb),var(--vz-text-opacity))!important
}

.text-light {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-light-rgb),var(--vz-text-opacity))!important
}

.text-dark {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-dark-rgb),var(--vz-text-opacity))!important
}

.text-black {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-black-rgb),var(--vz-text-opacity))!important
}

.text-white {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-white-rgb),var(--vz-text-opacity))!important
}

.text-body {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-body-color-rgb),var(--vz-text-opacity))!important
}

.text-muted {
    --vz-text-opacity: 1;
    color: #878a99!important
}

.text-black-50 {
    --vz-text-opacity: 1;
    color: rgba(0,0,0,.5)!important
}

.text-white-50 {
    --vz-text-opacity: 1;
    color: rgba(255,255,255,.5)!important
}

.text-reset {
    --vz-text-opacity: 1;
    color: inherit!important
}

.text-opacity-25 {
    --vz-text-opacity: 0.25
}

.text-opacity-50 {
    --vz-text-opacity: 0.5
}

.text-opacity-75 {
    --vz-text-opacity: 0.75
}

.text-opacity-100 {
    --vz-text-opacity: 1
}

.bg-primary {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-primary-rgb),var(--vz-bg-opacity))!important
}

.bg-secondary {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-secondary-rgb),var(--vz-bg-opacity))!important
}

.bg-success {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-success-rgb),var(--vz-bg-opacity))!important
}

.bg-info {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-info-rgb),var(--vz-bg-opacity))!important
}

.bg-warning {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-warning-rgb),var(--vz-bg-opacity))!important
}

.bg-danger {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-danger-rgb),var(--vz-bg-opacity))!important
}

.bg-light {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-light-rgb),var(--vz-bg-opacity))!important
}

.bg-dark {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-dark-rgb),var(--vz-bg-opacity))!important
}

.bg-black {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-black-rgb),var(--vz-bg-opacity))!important
}

.bg-white {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-white-rgb),var(--vz-bg-opacity))!important
}

.bg-body {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-body-bg-rgb),var(--vz-bg-opacity))!important
}

.bg-transparent {
    --vz-bg-opacity: 1;
    background-color: transparent!important
}

.bg-opacity-10 {
    --vz-bg-opacity: 0.1
}

.bg-opacity-25 {
    --vz-bg-opacity: 0.25
}

.bg-opacity-50 {
    --vz-bg-opacity: 0.5
}

.bg-opacity-75 {
    --vz-bg-opacity: 0.75
}

.bg-opacity-100 {
    --vz-bg-opacity: 1
}

.bg-gradient {
    background-image: var(--vz-gradient)!important
}

.user-select-all {
    -webkit-user-select: all!important;
    -moz-user-select: all!important;
    user-select: all!important
}

.user-select-auto {
    -webkit-user-select: auto!important;
    -moz-user-select: auto!important;
    -ms-user-select: auto!important;
    user-select: auto!important
}

.user-select-none {
    -webkit-user-select: none!important;
    -moz-user-select: none!important;
    -ms-user-select: none!important;
    user-select: none!important
}

.pe-none {
    pointer-events: none!important
}

.pe-auto {
    pointer-events: auto!important
}

.rounded {
    border-radius: var(--vz-border-radius)!important
}

.rounded-0 {
    border-radius: 0!important
}

.rounded-1 {
    border-radius: var(--vz-border-radius-sm)!important
}

.rounded-2 {
    border-radius: var(--vz-border-radius)!important
}

.rounded-3 {
    border-radius: var(--vz-border-radius-lg)!important
}

.rounded-4 {
    border-radius: var(--vz-border-radius-xl)!important
}

.rounded-5 {
    border-radius: var(--vz-border-radius-2xl)!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-pill {
    border-radius: var(--vz-border-radius-pill)!important
}

.rounded-top {
    border-top-left-radius: var(--vz-border-radius)!important;
    border-top-right-radius: var(--vz-border-radius)!important
}

.rounded-end {
    border-top-right-radius: var(--vz-border-radius)!important;
    border-bottom-right-radius: var(--vz-border-radius)!important
}

.rounded-bottom {
    border-bottom-right-radius: var(--vz-border-radius)!important;
    border-bottom-left-radius: var(--vz-border-radius)!important
}

.rounded-start {
    border-bottom-left-radius: var(--vz-border-radius)!important;
    border-top-left-radius: var(--vz-border-radius)!important
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

@media (min-width: 576px) {
    .float-sm-start {
        float:left!important
    }

    .float-sm-end {
        float: right!important
    }

    .float-sm-none {
        float: none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-grid {
        display: grid!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-sm-none {
        display: none!important
    }

    .flex-sm-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-sm-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-sm-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-sm-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-sm-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-sm-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-sm-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-sm-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-sm-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-sm-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-sm-0 {
        margin: 0!important
    }

    .m-sm-1 {
        margin: .25rem!important
    }

    .m-sm-2 {
        margin: .5rem!important
    }

    .m-sm-3 {
        margin: 1rem!important
    }

    .m-sm-4 {
        margin: 1.5rem!important
    }

    .m-sm-5 {
        margin: 3rem!important
    }

    .m-sm-auto {
        margin: auto!important
    }

    .mx-sm-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-sm-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-sm-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-sm-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-sm-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-sm-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-sm-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-sm-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-sm-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-sm-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-sm-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-sm-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-sm-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-sm-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-sm-0 {
        margin-top: 0!important
    }

    .mt-sm-1 {
        margin-top: .25rem!important
    }

    .mt-sm-2 {
        margin-top: .5rem!important
    }

    .mt-sm-3 {
        margin-top: 1rem!important
    }

    .mt-sm-4 {
        margin-top: 1.5rem!important
    }

    .mt-sm-5 {
        margin-top: 3rem!important
    }

    .mt-sm-auto {
        margin-top: auto!important
    }

    .me-sm-0 {
        margin-right: 0!important
    }

    .me-sm-1 {
        margin-right: .25rem!important
    }

    .me-sm-2 {
        margin-right: .5rem!important
    }

    .me-sm-3 {
        margin-right: 1rem!important
    }

    .me-sm-4 {
        margin-right: 1.5rem!important
    }

    .me-sm-5 {
        margin-right: 3rem!important
    }

    .me-sm-auto {
        margin-right: auto!important
    }

    .mb-sm-0 {
        margin-bottom: 0!important
    }

    .mb-sm-1 {
        margin-bottom: .25rem!important
    }

    .mb-sm-2 {
        margin-bottom: .5rem!important
    }

    .mb-sm-3 {
        margin-bottom: 1rem!important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-sm-5 {
        margin-bottom: 3rem!important
    }

    .mb-sm-auto {
        margin-bottom: auto!important
    }

    .ms-sm-0 {
        margin-left: 0!important
    }

    .ms-sm-1 {
        margin-left: .25rem!important
    }

    .ms-sm-2 {
        margin-left: .5rem!important
    }

    .ms-sm-3 {
        margin-left: 1rem!important
    }

    .ms-sm-4 {
        margin-left: 1.5rem!important
    }

    .ms-sm-5 {
        margin-left: 3rem!important
    }

    .ms-sm-auto {
        margin-left: auto!important
    }

    .m-sm-n1 {
        margin: -.25rem!important
    }

    .m-sm-n2 {
        margin: -.5rem!important
    }

    .m-sm-n3 {
        margin: -1rem!important
    }

    .m-sm-n4 {
        margin: -1.5rem!important
    }

    .m-sm-n5 {
        margin: -3rem!important
    }

    .mx-sm-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-sm-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-sm-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-sm-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-sm-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .my-sm-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-sm-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-sm-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-sm-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-sm-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .mt-sm-n1 {
        margin-top: -.25rem!important
    }

    .mt-sm-n2 {
        margin-top: -.5rem!important
    }

    .mt-sm-n3 {
        margin-top: -1rem!important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem!important
    }

    .mt-sm-n5 {
        margin-top: -3rem!important
    }

    .me-sm-n1 {
        margin-right: -.25rem!important
    }

    .me-sm-n2 {
        margin-right: -.5rem!important
    }

    .me-sm-n3 {
        margin-right: -1rem!important
    }

    .me-sm-n4 {
        margin-right: -1.5rem!important
    }

    .me-sm-n5 {
        margin-right: -3rem!important
    }

    .mb-sm-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-sm-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem!important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-sm-n5 {
        margin-bottom: -3rem!important
    }

    .ms-sm-n1 {
        margin-left: -.25rem!important
    }

    .ms-sm-n2 {
        margin-left: -.5rem!important
    }

    .ms-sm-n3 {
        margin-left: -1rem!important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem!important
    }

    .ms-sm-n5 {
        margin-left: -3rem!important
    }

    .p-sm-0 {
        padding: 0!important
    }

    .p-sm-1 {
        padding: .25rem!important
    }

    .p-sm-2 {
        padding: .5rem!important
    }

    .p-sm-3 {
        padding: 1rem!important
    }

    .p-sm-4 {
        padding: 1.5rem!important
    }

    .p-sm-5 {
        padding: 3rem!important
    }

    .px-sm-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-sm-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-sm-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-sm-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-sm-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-sm-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-sm-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-sm-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-sm-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-sm-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-sm-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-sm-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-sm-0 {
        padding-top: 0!important
    }

    .pt-sm-1 {
        padding-top: .25rem!important
    }

    .pt-sm-2 {
        padding-top: .5rem!important
    }

    .pt-sm-3 {
        padding-top: 1rem!important
    }

    .pt-sm-4 {
        padding-top: 1.5rem!important
    }

    .pt-sm-5 {
        padding-top: 3rem!important
    }

    .pe-sm-0 {
        padding-right: 0!important
    }

    .pe-sm-1 {
        padding-right: .25rem!important
    }

    .pe-sm-2 {
        padding-right: .5rem!important
    }

    .pe-sm-3 {
        padding-right: 1rem!important
    }

    .pe-sm-4 {
        padding-right: 1.5rem!important
    }

    .pe-sm-5 {
        padding-right: 3rem!important
    }

    .pb-sm-0 {
        padding-bottom: 0!important
    }

    .pb-sm-1 {
        padding-bottom: .25rem!important
    }

    .pb-sm-2 {
        padding-bottom: .5rem!important
    }

    .pb-sm-3 {
        padding-bottom: 1rem!important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-sm-5 {
        padding-bottom: 3rem!important
    }

    .ps-sm-0 {
        padding-left: 0!important
    }

    .ps-sm-1 {
        padding-left: .25rem!important
    }

    .ps-sm-2 {
        padding-left: .5rem!important
    }

    .ps-sm-3 {
        padding-left: 1rem!important
    }

    .ps-sm-4 {
        padding-left: 1.5rem!important
    }

    .ps-sm-5 {
        padding-left: 3rem!important
    }

    .gap-sm-0 {
        gap: 0!important
    }

    .gap-sm-1 {
        gap: .25rem!important
    }

    .gap-sm-2 {
        gap: .5rem!important
    }

    .gap-sm-3 {
        gap: 1rem!important
    }

    .gap-sm-4 {
        gap: 1.5rem!important
    }

    .gap-sm-5 {
        gap: 3rem!important
    }

    .text-sm-start {
        text-align: left!important
    }

    .text-sm-end {
        text-align: right!important
    }

    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width: 768px) {
    .float-md-start {
        float:left!important
    }

    .float-md-end {
        float: right!important
    }

    .float-md-none {
        float: none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-grid {
        display: grid!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-md-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-md-none {
        display: none!important
    }

    .flex-md-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-md-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-md-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-md-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-md-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-md-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-md-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-md-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-md-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-md-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-md-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-md-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-md-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-md-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-md-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-md-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-md-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-md-0 {
        margin: 0!important
    }

    .m-md-1 {
        margin: .25rem!important
    }

    .m-md-2 {
        margin: .5rem!important
    }

    .m-md-3 {
        margin: 1rem!important
    }

    .m-md-4 {
        margin: 1.5rem!important
    }

    .m-md-5 {
        margin: 3rem!important
    }

    .m-md-auto {
        margin: auto!important
    }

    .mx-md-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-md-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-md-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-md-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-md-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-md-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-md-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-md-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-md-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-md-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-md-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-md-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-md-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-md-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-md-0 {
        margin-top: 0!important
    }

    .mt-md-1 {
        margin-top: .25rem!important
    }

    .mt-md-2 {
        margin-top: .5rem!important
    }

    .mt-md-3 {
        margin-top: 1rem!important
    }

    .mt-md-4 {
        margin-top: 1.5rem!important
    }

    .mt-md-5 {
        margin-top: 3rem!important
    }

    .mt-md-auto {
        margin-top: auto!important
    }

    .me-md-0 {
        margin-right: 0!important
    }

    .me-md-1 {
        margin-right: .25rem!important
    }

    .me-md-2 {
        margin-right: .5rem!important
    }

    .me-md-3 {
        margin-right: 1rem!important
    }

    .me-md-4 {
        margin-right: 1.5rem!important
    }

    .me-md-5 {
        margin-right: 3rem!important
    }

    .me-md-auto {
        margin-right: auto!important
    }

    .mb-md-0 {
        margin-bottom: 0!important
    }

    .mb-md-1 {
        margin-bottom: .25rem!important
    }

    .mb-md-2 {
        margin-bottom: .5rem!important
    }

    .mb-md-3 {
        margin-bottom: 1rem!important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-md-5 {
        margin-bottom: 3rem!important
    }

    .mb-md-auto {
        margin-bottom: auto!important
    }

    .ms-md-0 {
        margin-left: 0!important
    }

    .ms-md-1 {
        margin-left: .25rem!important
    }

    .ms-md-2 {
        margin-left: .5rem!important
    }

    .ms-md-3 {
        margin-left: 1rem!important
    }

    .ms-md-4 {
        margin-left: 1.5rem!important
    }

    .ms-md-5 {
        margin-left: 3rem!important
    }

    .ms-md-auto {
        margin-left: auto!important
    }

    .m-md-n1 {
        margin: -.25rem!important
    }

    .m-md-n2 {
        margin: -.5rem!important
    }

    .m-md-n3 {
        margin: -1rem!important
    }

    .m-md-n4 {
        margin: -1.5rem!important
    }

    .m-md-n5 {
        margin: -3rem!important
    }

    .mx-md-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-md-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-md-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-md-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-md-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .my-md-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-md-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-md-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-md-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-md-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .mt-md-n1 {
        margin-top: -.25rem!important
    }

    .mt-md-n2 {
        margin-top: -.5rem!important
    }

    .mt-md-n3 {
        margin-top: -1rem!important
    }

    .mt-md-n4 {
        margin-top: -1.5rem!important
    }

    .mt-md-n5 {
        margin-top: -3rem!important
    }

    .me-md-n1 {
        margin-right: -.25rem!important
    }

    .me-md-n2 {
        margin-right: -.5rem!important
    }

    .me-md-n3 {
        margin-right: -1rem!important
    }

    .me-md-n4 {
        margin-right: -1.5rem!important
    }

    .me-md-n5 {
        margin-right: -3rem!important
    }

    .mb-md-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-md-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-md-n3 {
        margin-bottom: -1rem!important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-md-n5 {
        margin-bottom: -3rem!important
    }

    .ms-md-n1 {
        margin-left: -.25rem!important
    }

    .ms-md-n2 {
        margin-left: -.5rem!important
    }

    .ms-md-n3 {
        margin-left: -1rem!important
    }

    .ms-md-n4 {
        margin-left: -1.5rem!important
    }

    .ms-md-n5 {
        margin-left: -3rem!important
    }

    .p-md-0 {
        padding: 0!important
    }

    .p-md-1 {
        padding: .25rem!important
    }

    .p-md-2 {
        padding: .5rem!important
    }

    .p-md-3 {
        padding: 1rem!important
    }

    .p-md-4 {
        padding: 1.5rem!important
    }

    .p-md-5 {
        padding: 3rem!important
    }

    .px-md-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-md-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-md-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-md-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-md-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-md-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-md-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-md-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-md-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-md-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-md-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-md-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-md-0 {
        padding-top: 0!important
    }

    .pt-md-1 {
        padding-top: .25rem!important
    }

    .pt-md-2 {
        padding-top: .5rem!important
    }

    .pt-md-3 {
        padding-top: 1rem!important
    }

    .pt-md-4 {
        padding-top: 1.5rem!important
    }

    .pt-md-5 {
        padding-top: 3rem!important
    }

    .pe-md-0 {
        padding-right: 0!important
    }

    .pe-md-1 {
        padding-right: .25rem!important
    }

    .pe-md-2 {
        padding-right: .5rem!important
    }

    .pe-md-3 {
        padding-right: 1rem!important
    }

    .pe-md-4 {
        padding-right: 1.5rem!important
    }

    .pe-md-5 {
        padding-right: 3rem!important
    }

    .pb-md-0 {
        padding-bottom: 0!important
    }

    .pb-md-1 {
        padding-bottom: .25rem!important
    }

    .pb-md-2 {
        padding-bottom: .5rem!important
    }

    .pb-md-3 {
        padding-bottom: 1rem!important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-md-5 {
        padding-bottom: 3rem!important
    }

    .ps-md-0 {
        padding-left: 0!important
    }

    .ps-md-1 {
        padding-left: .25rem!important
    }

    .ps-md-2 {
        padding-left: .5rem!important
    }

    .ps-md-3 {
        padding-left: 1rem!important
    }

    .ps-md-4 {
        padding-left: 1.5rem!important
    }

    .ps-md-5 {
        padding-left: 3rem!important
    }

    .gap-md-0 {
        gap: 0!important
    }

    .gap-md-1 {
        gap: .25rem!important
    }

    .gap-md-2 {
        gap: .5rem!important
    }

    .gap-md-3 {
        gap: 1rem!important
    }

    .gap-md-4 {
        gap: 1.5rem!important
    }

    .gap-md-5 {
        gap: 3rem!important
    }

    .text-md-start {
        text-align: left!important
    }

    .text-md-end {
        text-align: right!important
    }

    .text-md-center {
        text-align: center!important
    }
}

@media (min-width: 992px) {
    .float-lg-start {
        float:left!important
    }

    .float-lg-end {
        float: right!important
    }

    .float-lg-none {
        float: none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-grid {
        display: grid!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-lg-none {
        display: none!important
    }

    .flex-lg-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-lg-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-lg-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-lg-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-lg-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-lg-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-lg-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-lg-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-lg-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-lg-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-lg-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-lg-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-lg-0 {
        margin: 0!important
    }

    .m-lg-1 {
        margin: .25rem!important
    }

    .m-lg-2 {
        margin: .5rem!important
    }

    .m-lg-3 {
        margin: 1rem!important
    }

    .m-lg-4 {
        margin: 1.5rem!important
    }

    .m-lg-5 {
        margin: 3rem!important
    }

    .m-lg-auto {
        margin: auto!important
    }

    .mx-lg-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-lg-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-lg-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-lg-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-lg-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-lg-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-lg-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-lg-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-lg-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-lg-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-lg-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-lg-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-lg-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-lg-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-lg-0 {
        margin-top: 0!important
    }

    .mt-lg-1 {
        margin-top: .25rem!important
    }

    .mt-lg-2 {
        margin-top: .5rem!important
    }

    .mt-lg-3 {
        margin-top: 1rem!important
    }

    .mt-lg-4 {
        margin-top: 1.5rem!important
    }

    .mt-lg-5 {
        margin-top: 3rem!important
    }

    .mt-lg-auto {
        margin-top: auto!important
    }

    .me-lg-0 {
        margin-right: 0!important
    }

    .me-lg-1 {
        margin-right: .25rem!important
    }

    .me-lg-2 {
        margin-right: .5rem!important
    }

    .me-lg-3 {
        margin-right: 1rem!important
    }

    .me-lg-4 {
        margin-right: 1.5rem!important
    }

    .me-lg-5 {
        margin-right: 3rem!important
    }

    .me-lg-auto {
        margin-right: auto!important
    }

    .mb-lg-0 {
        margin-bottom: 0!important
    }

    .mb-lg-1 {
        margin-bottom: .25rem!important
    }

    .mb-lg-2 {
        margin-bottom: .5rem!important
    }

    .mb-lg-3 {
        margin-bottom: 1rem!important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-lg-5 {
        margin-bottom: 3rem!important
    }

    .mb-lg-auto {
        margin-bottom: auto!important
    }

    .ms-lg-0 {
        margin-left: 0!important
    }

    .ms-lg-1 {
        margin-left: .25rem!important
    }

    .ms-lg-2 {
        margin-left: .5rem!important
    }

    .ms-lg-3 {
        margin-left: 1rem!important
    }

    .ms-lg-4 {
        margin-left: 1.5rem!important
    }

    .ms-lg-5 {
        margin-left: 3rem!important
    }

    .ms-lg-auto {
        margin-left: auto!important
    }

    .m-lg-n1 {
        margin: -.25rem!important
    }

    .m-lg-n2 {
        margin: -.5rem!important
    }

    .m-lg-n3 {
        margin: -1rem!important
    }

    .m-lg-n4 {
        margin: -1.5rem!important
    }

    .m-lg-n5 {
        margin: -3rem!important
    }

    .mx-lg-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-lg-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-lg-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-lg-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-lg-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .my-lg-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-lg-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-lg-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-lg-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-lg-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .mt-lg-n1 {
        margin-top: -.25rem!important
    }

    .mt-lg-n2 {
        margin-top: -.5rem!important
    }

    .mt-lg-n3 {
        margin-top: -1rem!important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem!important
    }

    .mt-lg-n5 {
        margin-top: -3rem!important
    }

    .me-lg-n1 {
        margin-right: -.25rem!important
    }

    .me-lg-n2 {
        margin-right: -.5rem!important
    }

    .me-lg-n3 {
        margin-right: -1rem!important
    }

    .me-lg-n4 {
        margin-right: -1.5rem!important
    }

    .me-lg-n5 {
        margin-right: -3rem!important
    }

    .mb-lg-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-lg-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem!important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-lg-n5 {
        margin-bottom: -3rem!important
    }

    .ms-lg-n1 {
        margin-left: -.25rem!important
    }

    .ms-lg-n2 {
        margin-left: -.5rem!important
    }

    .ms-lg-n3 {
        margin-left: -1rem!important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem!important
    }

    .ms-lg-n5 {
        margin-left: -3rem!important
    }

    .p-lg-0 {
        padding: 0!important
    }

    .p-lg-1 {
        padding: .25rem!important
    }

    .p-lg-2 {
        padding: .5rem!important
    }

    .p-lg-3 {
        padding: 1rem!important
    }

    .p-lg-4 {
        padding: 1.5rem!important
    }

    .p-lg-5 {
        padding: 3rem!important
    }

    .px-lg-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-lg-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-lg-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-lg-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-lg-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-lg-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-lg-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-lg-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-lg-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-lg-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-lg-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-lg-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-lg-0 {
        padding-top: 0!important
    }

    .pt-lg-1 {
        padding-top: .25rem!important
    }

    .pt-lg-2 {
        padding-top: .5rem!important
    }

    .pt-lg-3 {
        padding-top: 1rem!important
    }

    .pt-lg-4 {
        padding-top: 1.5rem!important
    }

    .pt-lg-5 {
        padding-top: 3rem!important
    }

    .pe-lg-0 {
        padding-right: 0!important
    }

    .pe-lg-1 {
        padding-right: .25rem!important
    }

    .pe-lg-2 {
        padding-right: .5rem!important
    }

    .pe-lg-3 {
        padding-right: 1rem!important
    }

    .pe-lg-4 {
        padding-right: 1.5rem!important
    }

    .pe-lg-5 {
        padding-right: 3rem!important
    }

    .pb-lg-0 {
        padding-bottom: 0!important
    }

    .pb-lg-1 {
        padding-bottom: .25rem!important
    }

    .pb-lg-2 {
        padding-bottom: .5rem!important
    }

    .pb-lg-3 {
        padding-bottom: 1rem!important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-lg-5 {
        padding-bottom: 3rem!important
    }

    .ps-lg-0 {
        padding-left: 0!important
    }

    .ps-lg-1 {
        padding-left: .25rem!important
    }

    .ps-lg-2 {
        padding-left: .5rem!important
    }

    .ps-lg-3 {
        padding-left: 1rem!important
    }

    .ps-lg-4 {
        padding-left: 1.5rem!important
    }

    .ps-lg-5 {
        padding-left: 3rem!important
    }

    .gap-lg-0 {
        gap: 0!important
    }

    .gap-lg-1 {
        gap: .25rem!important
    }

    .gap-lg-2 {
        gap: .5rem!important
    }

    .gap-lg-3 {
        gap: 1rem!important
    }

    .gap-lg-4 {
        gap: 1.5rem!important
    }

    .gap-lg-5 {
        gap: 3rem!important
    }

    .text-lg-start {
        text-align: left!important
    }

    .text-lg-end {
        text-align: right!important
    }

    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width: 1200px) {
    .float-xl-start {
        float:left!important
    }

    .float-xl-end {
        float: right!important
    }

    .float-xl-none {
        float: none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-grid {
        display: grid!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-xl-none {
        display: none!important
    }

    .flex-xl-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-xl-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xl-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-xl-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xl-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xl-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xl-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-xl-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-xl-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xl-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xl-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-xl-0 {
        margin: 0!important
    }

    .m-xl-1 {
        margin: .25rem!important
    }

    .m-xl-2 {
        margin: .5rem!important
    }

    .m-xl-3 {
        margin: 1rem!important
    }

    .m-xl-4 {
        margin: 1.5rem!important
    }

    .m-xl-5 {
        margin: 3rem!important
    }

    .m-xl-auto {
        margin: auto!important
    }

    .mx-xl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xl-0 {
        margin-top: 0!important
    }

    .mt-xl-1 {
        margin-top: .25rem!important
    }

    .mt-xl-2 {
        margin-top: .5rem!important
    }

    .mt-xl-3 {
        margin-top: 1rem!important
    }

    .mt-xl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xl-5 {
        margin-top: 3rem!important
    }

    .mt-xl-auto {
        margin-top: auto!important
    }

    .me-xl-0 {
        margin-right: 0!important
    }

    .me-xl-1 {
        margin-right: .25rem!important
    }

    .me-xl-2 {
        margin-right: .5rem!important
    }

    .me-xl-3 {
        margin-right: 1rem!important
    }

    .me-xl-4 {
        margin-right: 1.5rem!important
    }

    .me-xl-5 {
        margin-right: 3rem!important
    }

    .me-xl-auto {
        margin-right: auto!important
    }

    .mb-xl-0 {
        margin-bottom: 0!important
    }

    .mb-xl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xl-auto {
        margin-bottom: auto!important
    }

    .ms-xl-0 {
        margin-left: 0!important
    }

    .ms-xl-1 {
        margin-left: .25rem!important
    }

    .ms-xl-2 {
        margin-left: .5rem!important
    }

    .ms-xl-3 {
        margin-left: 1rem!important
    }

    .ms-xl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xl-5 {
        margin-left: 3rem!important
    }

    .ms-xl-auto {
        margin-left: auto!important
    }

    .m-xl-n1 {
        margin: -.25rem!important
    }

    .m-xl-n2 {
        margin: -.5rem!important
    }

    .m-xl-n3 {
        margin: -1rem!important
    }

    .m-xl-n4 {
        margin: -1.5rem!important
    }

    .m-xl-n5 {
        margin: -3rem!important
    }

    .mx-xl-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-xl-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-xl-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-xl-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-xl-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .my-xl-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-xl-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-xl-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-xl-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-xl-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .mt-xl-n1 {
        margin-top: -.25rem!important
    }

    .mt-xl-n2 {
        margin-top: -.5rem!important
    }

    .mt-xl-n3 {
        margin-top: -1rem!important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem!important
    }

    .mt-xl-n5 {
        margin-top: -3rem!important
    }

    .me-xl-n1 {
        margin-right: -.25rem!important
    }

    .me-xl-n2 {
        margin-right: -.5rem!important
    }

    .me-xl-n3 {
        margin-right: -1rem!important
    }

    .me-xl-n4 {
        margin-right: -1.5rem!important
    }

    .me-xl-n5 {
        margin-right: -3rem!important
    }

    .mb-xl-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-xl-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem!important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-xl-n5 {
        margin-bottom: -3rem!important
    }

    .ms-xl-n1 {
        margin-left: -.25rem!important
    }

    .ms-xl-n2 {
        margin-left: -.5rem!important
    }

    .ms-xl-n3 {
        margin-left: -1rem!important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem!important
    }

    .ms-xl-n5 {
        margin-left: -3rem!important
    }

    .p-xl-0 {
        padding: 0!important
    }

    .p-xl-1 {
        padding: .25rem!important
    }

    .p-xl-2 {
        padding: .5rem!important
    }

    .p-xl-3 {
        padding: 1rem!important
    }

    .p-xl-4 {
        padding: 1.5rem!important
    }

    .p-xl-5 {
        padding: 3rem!important
    }

    .px-xl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-xl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-xl-0 {
        padding-top: 0!important
    }

    .pt-xl-1 {
        padding-top: .25rem!important
    }

    .pt-xl-2 {
        padding-top: .5rem!important
    }

    .pt-xl-3 {
        padding-top: 1rem!important
    }

    .pt-xl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xl-5 {
        padding-top: 3rem!important
    }

    .pe-xl-0 {
        padding-right: 0!important
    }

    .pe-xl-1 {
        padding-right: .25rem!important
    }

    .pe-xl-2 {
        padding-right: .5rem!important
    }

    .pe-xl-3 {
        padding-right: 1rem!important
    }

    .pe-xl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xl-5 {
        padding-right: 3rem!important
    }

    .pb-xl-0 {
        padding-bottom: 0!important
    }

    .pb-xl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xl-5 {
        padding-bottom: 3rem!important
    }

    .ps-xl-0 {
        padding-left: 0!important
    }

    .ps-xl-1 {
        padding-left: .25rem!important
    }

    .ps-xl-2 {
        padding-left: .5rem!important
    }

    .ps-xl-3 {
        padding-left: 1rem!important
    }

    .ps-xl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xl-5 {
        padding-left: 3rem!important
    }

    .gap-xl-0 {
        gap: 0!important
    }

    .gap-xl-1 {
        gap: .25rem!important
    }

    .gap-xl-2 {
        gap: .5rem!important
    }

    .gap-xl-3 {
        gap: 1rem!important
    }

    .gap-xl-4 {
        gap: 1.5rem!important
    }

    .gap-xl-5 {
        gap: 3rem!important
    }

    .text-xl-start {
        text-align: left!important
    }

    .text-xl-end {
        text-align: right!important
    }

    .text-xl-center {
        text-align: center!important
    }
}

@media (min-width: 1400px) {
    .float-xxl-start {
        float:left!important
    }

    .float-xxl-end {
        float: right!important
    }

    .float-xxl-none {
        float: none!important
    }

    .d-xxl-inline {
        display: inline!important
    }

    .d-xxl-inline-block {
        display: inline-block!important
    }

    .d-xxl-block {
        display: block!important
    }

    .d-xxl-grid {
        display: grid!important
    }

    .d-xxl-table {
        display: table!important
    }

    .d-xxl-table-row {
        display: table-row!important
    }

    .d-xxl-table-cell {
        display: table-cell!important
    }

    .d-xxl-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xxl-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-xxl-none {
        display: none!important
    }

    .flex-xxl-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-xxl-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xxl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xxl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xxl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xxl-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-xxl-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-xxl-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-xxl-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xxl-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xxl-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xxl-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xxl-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-xxl-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-xxl-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xxl-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xxl-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xxl-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xxl-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-xxl-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-xxl-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-xxl-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-xxl-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-xxl-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-xxl-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-xxl-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-xxl-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-xxl-0 {
        margin: 0!important
    }

    .m-xxl-1 {
        margin: .25rem!important
    }

    .m-xxl-2 {
        margin: .5rem!important
    }

    .m-xxl-3 {
        margin: 1rem!important
    }

    .m-xxl-4 {
        margin: 1.5rem!important
    }

    .m-xxl-5 {
        margin: 3rem!important
    }

    .m-xxl-auto {
        margin: auto!important
    }

    .mx-xxl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xxl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xxl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xxl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xxl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xxl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xxl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xxl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xxl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xxl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xxl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xxl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xxl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xxl-0 {
        margin-top: 0!important
    }

    .mt-xxl-1 {
        margin-top: .25rem!important
    }

    .mt-xxl-2 {
        margin-top: .5rem!important
    }

    .mt-xxl-3 {
        margin-top: 1rem!important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xxl-5 {
        margin-top: 3rem!important
    }

    .mt-xxl-auto {
        margin-top: auto!important
    }

    .me-xxl-0 {
        margin-right: 0!important
    }

    .me-xxl-1 {
        margin-right: .25rem!important
    }

    .me-xxl-2 {
        margin-right: .5rem!important
    }

    .me-xxl-3 {
        margin-right: 1rem!important
    }

    .me-xxl-4 {
        margin-right: 1.5rem!important
    }

    .me-xxl-5 {
        margin-right: 3rem!important
    }

    .me-xxl-auto {
        margin-right: auto!important
    }

    .mb-xxl-0 {
        margin-bottom: 0!important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xxl-auto {
        margin-bottom: auto!important
    }

    .ms-xxl-0 {
        margin-left: 0!important
    }

    .ms-xxl-1 {
        margin-left: .25rem!important
    }

    .ms-xxl-2 {
        margin-left: .5rem!important
    }

    .ms-xxl-3 {
        margin-left: 1rem!important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xxl-5 {
        margin-left: 3rem!important
    }

    .ms-xxl-auto {
        margin-left: auto!important
    }

    .m-xxl-n1 {
        margin: -.25rem!important
    }

    .m-xxl-n2 {
        margin: -.5rem!important
    }

    .m-xxl-n3 {
        margin: -1rem!important
    }

    .m-xxl-n4 {
        margin: -1.5rem!important
    }

    .m-xxl-n5 {
        margin: -3rem!important
    }

    .mx-xxl-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-xxl-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-xxl-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-xxl-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-xxl-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .my-xxl-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-xxl-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-xxl-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-xxl-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-xxl-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .mt-xxl-n1 {
        margin-top: -.25rem!important
    }

    .mt-xxl-n2 {
        margin-top: -.5rem!important
    }

    .mt-xxl-n3 {
        margin-top: -1rem!important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem!important
    }

    .mt-xxl-n5 {
        margin-top: -3rem!important
    }

    .me-xxl-n1 {
        margin-right: -.25rem!important
    }

    .me-xxl-n2 {
        margin-right: -.5rem!important
    }

    .me-xxl-n3 {
        margin-right: -1rem!important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem!important
    }

    .me-xxl-n5 {
        margin-right: -3rem!important
    }

    .mb-xxl-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-xxl-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem!important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-xxl-n5 {
        margin-bottom: -3rem!important
    }

    .ms-xxl-n1 {
        margin-left: -.25rem!important
    }

    .ms-xxl-n2 {
        margin-left: -.5rem!important
    }

    .ms-xxl-n3 {
        margin-left: -1rem!important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem!important
    }

    .ms-xxl-n5 {
        margin-left: -3rem!important
    }

    .p-xxl-0 {
        padding: 0!important
    }

    .p-xxl-1 {
        padding: .25rem!important
    }

    .p-xxl-2 {
        padding: .5rem!important
    }

    .p-xxl-3 {
        padding: 1rem!important
    }

    .p-xxl-4 {
        padding: 1.5rem!important
    }

    .p-xxl-5 {
        padding: 3rem!important
    }

    .px-xxl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xxl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xxl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xxl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xxl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xxl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-xxl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xxl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xxl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xxl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xxl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xxl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-xxl-0 {
        padding-top: 0!important
    }

    .pt-xxl-1 {
        padding-top: .25rem!important
    }

    .pt-xxl-2 {
        padding-top: .5rem!important
    }

    .pt-xxl-3 {
        padding-top: 1rem!important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xxl-5 {
        padding-top: 3rem!important
    }

    .pe-xxl-0 {
        padding-right: 0!important
    }

    .pe-xxl-1 {
        padding-right: .25rem!important
    }

    .pe-xxl-2 {
        padding-right: .5rem!important
    }

    .pe-xxl-3 {
        padding-right: 1rem!important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xxl-5 {
        padding-right: 3rem!important
    }

    .pb-xxl-0 {
        padding-bottom: 0!important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem!important
    }

    .ps-xxl-0 {
        padding-left: 0!important
    }

    .ps-xxl-1 {
        padding-left: .25rem!important
    }

    .ps-xxl-2 {
        padding-left: .5rem!important
    }

    .ps-xxl-3 {
        padding-left: 1rem!important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xxl-5 {
        padding-left: 3rem!important
    }

    .gap-xxl-0 {
        gap: 0!important
    }

    .gap-xxl-1 {
        gap: .25rem!important
    }

    .gap-xxl-2 {
        gap: .5rem!important
    }

    .gap-xxl-3 {
        gap: 1rem!important
    }

    .gap-xxl-4 {
        gap: 1.5rem!important
    }

    .gap-xxl-5 {
        gap: 3rem!important
    }

    .text-xxl-start {
        text-align: left!important
    }

    .text-xxl-end {
        text-align: right!important
    }

    .text-xxl-center {
        text-align: center!important
    }
}

@media (min-width: 1200px) {
    .fs-1 {
        font-size:2.03125rem!important
    }

    .fs-2 {
        font-size: 1.625rem!important
    }

    .fs-3 {
        font-size: 1.421875rem!important
    }
}

@media print {
    .d-print-inline {
        display: inline!important
    }

    .d-print-inline-block {
        display: inline-block!important
    }

    .d-print-block {
        display: block!important
    }

    .d-print-grid {
        display: grid!important
    }

    .d-print-table {
        display: table!important
    }

    .d-print-table-row {
        display: table-row!important
    }

    .d-print-table-cell {
        display: table-cell!important
    }

    .d-print-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-print-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-print-none {
        display: none!important
    }
}
