:root {
  --vz-vertical-menu-bg: #fff;
  --vz-vertical-menu-item-color: #6d7080;
  --vz-vertical-menu-item-hover-color: #405189;
  --vz-vertical-menu-item-active-color: #405189;
  --vz-vertical-menu-sub-item-color: #7c7f90;
  --vz-vertical-menu-sub-item-hover-color: #405189;
  --vz-vertical-menu-sub-item-active-color: #405189;
  --vz-vertical-menu-title-color: #919da9;
  --vz-vertical-menu-bg-dark: #3a6a23;
  //--vz-vertical-menu-bg-dark: linear-gradient(#497721, #213418);
  //--vz-vertical-menu-bg-dark: linear-gradient(#3e6819, #131f0d);;
  --vz-vertical-menu-item-color-dark: #fff;
  --vz-vertical-menu-item-hover-color-dark: #8bc34a;
  --vz-vertical-menu-item-active-color-dark: #fff;
  --vz-vertical-menu-sub-item-color-dark: #fff;
  --vz-vertical-menu-sub-item-hover-color-dark: #fff;
  --vz-vertical-menu-sub-item-active-color-dark: #fff;
  --vz-vertical-menu-title-color-dark: #fff;
  --vz-header-bg: #fff;
  --vz-header-item-color: #e9ecef;
  --vz-header-bg-dark: #405189;
  --vz-header-item-color-dark: #b0c4d9;
  --vz-topbar-search-bg: #f3f3f9;
  --vz-topbar-user-bg: #f8faf4;
  --vz-topbar-user-bg-dark: #52639c;
  --vz-footer-bg: #fff;
  --vz-footer-color: #98a6ad;
  --vz-topnav-bg: #fff;
  --vz-topnav-item-color: #6d7080;
  --vz-topnav-item-color-active: #405189;
  --vz-twocolumn-menu-iconview-bg: #fff;
  --vz-twocolumn-menu-bg: #fff;
  --vz-twocolumn-menu-iconview-bg-dark: var(--vz-vertical-menu-bg-dark);
  --vz-twocolumn-menu-bg-dark: #1b5e20;
  --vz-twocolumn-menu-item-color-dark: var(--vz-vertical-menu-item-color-dark);
  --vz-twocolumn-menu-item-active-color-dark: #fff;
  --vz-twocolumn-menu-item-active-bg-dark: rgba(255, 255, 255, 0.15);
  --vz-boxed-body-bg: #e5e5f2;
  --vz-heading-color: #495057;
  --vz-link-color: #405189;
  --vz-link-hover-color: #405189;
  --vz-border-color: #e9ebec;
  --vz-card-bg-custom: #fff;
  --vz-card-logo-dark: block;
  --vz-card-logo-light: none;
  --vz-list-group-hover-bg: #f3f6f9;
  --vz-input-bg: #fff;
  --vz-input-border: #d0dace;
  --vz-input-focus-border: #a8c4a0;
  --vz-input-disabled-bg: #eff2f7;
  --vz-input-group-addon-bg: #eff2f7;
  --vz-input-check-border: var(--vz-input-border);
}

///bootstrap

:root{
  --vz-blue: #3577f1;
  --vz-indigo: #405189;
  --vz-purple: #6559cc;
  --vz-pink: #f672a7;
  --vz-red: #f06548;
  --vz-orange: #f1963b;
  --vz-yellow: #f7b84b;
  --vz-green: #0ab39c;
  --vz-teal: #02a8b5;
  --vz-cyan: #299cdb;
  --vz-white: #fff;
  --vz-gray: #878a99;
  --vz-gray-dark: #343a40;
  --vz-gray-100: #f3f6f9;
  --vz-gray-200: #eff2f7;
  --vz-gray-300: #e9ebec;
  --vz-gray-400: #ced4da;
  --vz-gray-500: #adb5bd;
  --vz-gray-600: #878a99;
  --vz-gray-700: #495057;
  --vz-gray-800: #343a40;
  --vz-gray-900: #212529;
  --vz-primary: #405189;
  --vz-secondary: #3577f1;
  --vz-success: #0ab39c;
  --vz-info: #299cdb;
  --vz-warning: #f7b84b;
  --vz-danger: #f06548;
  --vz-danger-bg-subtle: #fde8e4;
  --vz-primary-bg-subtle: #e2e5ed;
  --vz-secondary-bg-subtle: #e1ebfd;
  --vz-success-bg-subtle: #d2e7c5;
  --vz-info-bg-subtle: #dff0fa;
  --vz-warning-bg-subtle: #fef4e4;
  --vz-light-bg-subtle: #f9fbfc;
  --vz-dark-bg-subtle: #e9ebec;
  --vz-light: #f3f6f9;
  --vz-dark: #212529;
  --vz-primary-rgb: 51, 105, 30;
  --vz-secondary-rgb: 53,119,241;
  --vz-success-rgb: 104, 159, 56;
  --vz-info-rgb: 41,156,219;
  --vz-warning-rgb: 247,184,75;
  --vz-danger-rgb: 240,101,72;
  --vz-light-rgb:  248, 250, 244;
  --vz-dark-rgb: 33,37,41;
  --vz-white-rgb: 255,255,255;
  --vz-black-rgb: 0,0,0;
  --vz-body-color-rgb: 33,37,41;
  --vz-body-bg-rgb: 243,243,249;
  --vz-font-sans-serif: "Poppins",sans-serif;
  --vz-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --vz-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --vz-body-font-family: var(--vz-font-sans-serif);
  --vz-body-font-size: 0.8125rem;
  --vz-body-font-weight: 400;
  --vz-body-line-height: 1.5;
  --vz-body-color: #212529;
  --vz-body-bg: #f3f9f3;
  --vz-border-width: 1px;
  --vz-border-style: solid;
  --vz-border-color-translucent: rgba(0, 0, 0, 0.175);
  --vz-border-radius: 0.25rem;
  --vz-border-radius-sm: 0.2rem;
  --vz-border-radius-lg: 0.3rem;
  --vz-border-radius-xl: 1rem;
  --vz-border-radius-2xl: 2rem;
  --vz-border-radius-pill: 50rem;
  --vz-link-color: var(--vz-link-color);
  --vz-link-hover-color: var(--vz-link-hover-color);
  --vz-code-color: #f672a7;
  --vz-highlight-bg: #fcf8e3;
}

///html
html{
  .btn-success {
    --vz-btn-color: #fff;
    --vz-btn-bg: #689f38;
    --vz-btn-border-color: #689f38;
    --vz-btn-hover-color: #fff;
    --vz-btn-hover-bg: #558b2f;
    --vz-btn-hover-border-color: #558b2f;
    --vz-btn-focus-shadow-rgb: 47,190,171;
    --vz-btn-active-color: #fff;
    --vz-btn-active-bg: #558b2f;
    --vz-btn-active-border-color: #558b2f;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #fff;
    --vz-btn-disabled-bg: #689f38;
    --vz-btn-disabled-border-color: #689f38;
  }

  .auth-one-bg .bg-overlay {
    background: #c5e1a5;
  }

  .form-check-input:checked {
    background-color: #689f38;
    border-color: #689f38;
  }

  .btn-light {
    --vz-btn-color: #000;
    --vz-btn-bg: #f3f9f3;
    --vz-btn-border-color: #f3f9f3;
    --vz-btn-hover-color: #000;
    --vz-btn-hover-bg: #cfd4d0;
    --vz-btn-hover-border-color: #cfd4d0;
    --vz-btn-focus-shadow-rgb: 207,209,212;
    --vz-btn-active-color: #000;
    --vz-btn-active-bg: #c2c7c2;
    --vz-btn-active-border-color: #c2c7c2;
    --vz-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --vz-btn-disabled-color: #000;
    --vz-btn-disabled-bg: #f3f9f4;
    --vz-btn-disabled-border-color: #f3f9f4;
  }

  .btn-ghost-primary {
    --vz-btn-color: #689f38;
    --vz-btn-bg: transparent;
    --vz-btn-border-color: transparent;
    --vz-btn-hover-color: #ffffff;
    --vz-btn-hover-bg: rgba(64, 81, 137, 0.1);
    --vz-btn-hover-border-color: transparent;
    --vz-btn-focus-shadow-rgb: 64, 81, 137;
    --vz-btn-active-color: #689f38;
    --vz-btn-active-bg: rgba(243, 249, 243, 1);
    --vz-btn-active-border-color: transparent;
  }

  .pagination {
    --vz-pagination-padding-x: 0.75rem;
    --vz-pagination-padding-y: 0.375rem;
    --vz-pagination-font-size: 0.8125rem;
    --vz-pagination-color: var(--vz-link-color);
    --vz-pagination-bg: var(--vz-card-bg-custom);
    --vz-pagination-border-width: 1px;
    --vz-pagination-border-color: var(--vz-border-color);
    --vz-pagination-border-radius: 0.25rem;
    --vz-pagination-hover-color: var(--vz-link-hover-color);
    --vz-pagination-hover-bg: #f3f9f3;
    --vz-pagination-hover-border-color: var(--vz-border-color);
    --vz-pagination-focus-color: var(--vz-link-hover-color);
    --vz-pagination-focus-bg: #f3f9f3;
    --vz-pagination-focus-box-shadow: 0 0 0 0 rgba(64, 81, 137, 0.25);
    --vz-pagination-active-color: #fff;
    --vz-pagination-active-bg: #689f38;
    --vz-pagination-active-border-color: #689f38;
    --vz-pagination-disabled-color: #878a99;
    --vz-pagination-disabled-bg: var(--vz-card-bg-custom);
    --vz-pagination-disabled-border-color: var(--vz-border-color);
    display: flex;
    padding-left: 0;
    list-style: none;
  }

  .profile-wid-bg::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.9;
    background: #405189;
    background: linear-gradient(to top, #1e3217, #33691e);
  }

  .profile-project-card.profile-project-success {
    border-left-color: #689f38;
    transition: 0.3s all;
    &:hover{
      border-color: #689f38;
    }
  }

  .mini-btn{
    padding: 3px 8px;
    margin-top: 19px;
    font-size: 12px;
  }

  .modal.fade.show{
    background-color: #0000007a;
  }
  .sidebar-background{
    background-image: url("../img/default-background.webp");
  }
  .editbox{
    cursor: pointer;
  }
  .folder-card{
    cursor: pointer;
  }
  .folder-card  .bg-light{
    cursor: pointer;
    border: 1px solid #f8faf4;
  }
  .folder-card.active .bg-light{
    border: 1px solid #689f38;
  }
  .folder-card:hover .bg-light{
    border: 1px solid #689f38;
  }
  .simplebar-mask {
    z-index: unset;
  }
  .link-success{
    color: #689f38 !important;
    &:hover{
      color: #497825 !important;
    }
  }
  .file-manager-menu li span.active, .file-manager-menu li span:hover, .file-manager-menu li span[aria-expanded='true']{
    color: #689f38;
  }
  .file-manager-menu li a.active, .file-manager-menu li a.active span, .file-manager-menu li a:hover, .file-manager-menu li a[aria-expanded='true']{
    color: #689f38;
  }

  .form-control-edit{
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    padding: 0;
    width: unset;
    display: inline;
    background-color: transparent;
    &:focus{
      background-color: transparent;
    }
  }
  ul li i{
    opacity: 0;
    transition: 0.3s all;
  }

  ul li .active:hover i{
    opacity: 1;
  }
  .notification-dropdown{
    position: absolute;
    inset: 0 0 auto auto;
    margin: 0;
    transform: translate(0px, 75px);
  }

  .show .notification-btn{
    color: var(--vz-btn-hover-color);
    background-color: var(--vz-btn-hover-bg);
    border-color: var(--vz-btn-hover-border-color);

  }

  .notification-btn{
    position: relative;
  }
  .step-icon:before{
    display: none;
  }
  .vertical-navs-step .nav .nav-link.done{
    border-color: #689f38;
  }
  .vertical-navs-step .nav .nav-link.active{
    border-color: #3e6a24;
  }
  .vertical-navs-step .nav .nav-link.active .step-icon, .vertical-navs-step .nav .nav-link.done .step-icon{
    color: #689f38;
  }
  .saison-calendar .fc .fc-view{
    position: relative;
  }

  .saison-calendar .fc .fc-scroller-liquid-absolute{
    position: relative;
  }
  .title-red-banner{
    background-color: #ff0000;
    color: #fff;
  }

  .title-orangered-banner{
    background-color: #ff9c00;
    color: #000;
  }

  .title-orange-banner{
    background-color: #f3bf99;
    color: #000;
  }

  .title-green-banner{
    background-color: yellow;
    color: #000;
  }

  .form-check-success .form-check-input:checked{
    background-color: #689f38;
    border-color: #689f38;
  }

  .fc table .fc-day {
    position: relative;
    overflow: hidden;

    &:before {
      background-color: transparent;
      content: "";
      height: 48px;
      left: -24px;
      position: absolute;
      top: 9px;
      transform: rotate(-45deg);
      width: 48px;
    }

    &:after {
      background-color: transparent;
      content: "";
      height: 48px;
      left: 6px;
      position: absolute;
      top: 9px;
      transform: rotate(45deg);
      width: 48px;
    }
  }

  .fc{
    .fc-daygrid-day-number{
      border-radius: 0;
    }
  }

  #saison-calendar:not(.noShowSaison){
    .fc{
      .firstSeasonStart, .firstSeasonEnd, .firstSeason, .secondSeasonStart, .secondSeasonEnd, .secondSeason, .thirdSeasonEnd ,.thirdSeasonStart, .thirdSeason, .fourthSeasonEnd, .fourthSeasonStart, .fourthSeason{
        .fc-daygrid-day-number {
          align-items: end;
          background-color: transparent !important;
          color: #fff;
          justify-content: end;
        }
        .fc-daygrid-day-top {
          justify-content: end;
        }

        &.veryLowDensity .fc-daygrid-day-number {
          //color: #5ae78199;
          color: #5ae781
        }

        &.lowDensity .fc-daygrid-day-number {
          //color: #e9ea7099;
          color: #e9ea70;
        }

        &.midDensity .fc-daygrid-day-number {
          //color: #eac67099;
          color: #eac670;
        }

        &.highDensity .fc-daygrid-day-number {
          //color: #ea8d7099;
          color: #ea8d70;
        }

        &.veryHighDensity .fc-daygrid-day-number {
          //color: #f7404099;
          color: #f74040;
        }
      }
    }
    .fc {
      --fc-season1-color: #567EDB;
      --fc-season2-color: #2F62D7;
      --fc-season3-color: #0937A2;
      --fc-season4-color: #062A7E;

      .fc-daygrid-day-top {
        justify-content: center;
      }
    }

    .fc{
      .firstSeasonStart {
        &:after {
          background-color: var(--fc-season1-color) !important;
          border-left: 1px solid white;
        }
      }

      .firstSeasonEnd {
        &:before {
          background-color: var(--fc-season1-color) !important;
        }
      }

      .firstSeason {
        background-color: var(--fc-season1-color) !important;
      }

      .secondSeasonStart {
        &:after {
          background-color: var(--fc-season2-color) !important;
          border-left: 1px solid white;
        }
      }

      .secondSeasonEnd {
        &:before {
          background-color: var(--fc-season2-color) !important;
        }
      }

      .secondSeason {
        background-color: var(--fc-season2-color) !important;
      }

      .thirdSeasonStart {
        &:after {
          background-color: var(--fc-season3-color) !important;
          border-left: 1px solid white;
        }
      }

      .thirdSeasonEnd {
        &:before {
          background-color: var(--fc-season3-color) !important;
        }
      }

      .thirdSeason {
        background-color: var(--fc-season3-color) !important;
      }

      .fourthSeasonStart {
        &:after {
          background-color: var(--fc-season4-color) !important;
          border-left: 1px solid white;
        }
      }

      .fourthSeasonEnd {
        &:before {
          background-color: var(--fc-season4-color) !important;
        }
      }

      .fourthSeason {
        background-color: var(--fc-season4-color) !important;
      }
    }
  }


  .firstSeasonStart {
    &:after {
      background-color: #1a237e !important;
    }
  }

  .firstSeasonEnd {
    &:before {
      background-color: #1a237e !important;
    }
  }

  .firstSeason {
    background-color: #1a237e !important;
  }

  .secondSeasonStart {
    &:after {
      background-color: #1976d2 !important;
    }
  }

  .secondSeasonEnd {
    &:before {
      background-color: #1976d2 !important;
    }
  }

  .secondSeason {
    background-color: #1976d2 !important;
  }

  .thirdSeasonStart {
    &:after {
      background-color: #64b5f6 !important;
    }
  }

  .thirdSeasonEnd {
    &:before {
      background-color: #64b5f6 !important;
    }
  }

  .thirdSeason {
    background-color: #64b5f6 !important;
  }

  .fourthSeasonStart {
    &:after {
      background-color: #e1f5fe !important;
    }
  }

  .fourthSeasonEnd {
    &:before {
      background-color: #e1f5fe !important;
    }
  }

  .fourthSeason {
    background-color: #e1f5fe !important;
  }

  .veryLowDensity{
    .fc-daygrid-day-number {
      background-color: #5ae781;
    }
  }

  .lowDensity{
    .fc-daygrid-day-number {
      background-color: #e9ea70;
    }
  }

  .midDensity{
    .fc-daygrid-day-number {
      background-color: #eac670;
    }
  }

  .highDensity{
    .fc-daygrid-day-number {
      background-color: #ea8d70;
    }
  }

  .veryHighDensity{
    .fc-daygrid-day-number {
      background-color: #f74040;
    }
  }

  .noShowDensity{
    .fc-daygrid-day-number {
      background-color: rgba(255, 255, 255, 0.7) !important;
    }
  }

  .noShowSaison{
    .fc table td.fc-day {
      background-color: transparent !important;

      &:before {
        display: none !important;
      }

      &:after {
        display: none !important;
      }
    }
  }

  .fc {
    .fc-daygrid-day-top {
      justify-content: center;
    }
  }
  .dropdown-container-absolute{
    position: absolute;
    z-index: 1055;
    right: 0;
    margin: 1rem;
  }


  //OLAP TABELLE CSS
  .olap-card{
    div.dataTables_wrapper div.dataTables_filter input {
      margin-left: .5em;
      display: block;
      width: 100%;
    }

    div.dataTables_wrapper div.dataTables_filter label {
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
      width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .btn.btn-olap{
      padding: 0 15px;
      height: 27px;
      display: flex;
      align-items: center;
      &.btn-toggled {
        background-color: transparent;
        border: 1px solid var(--vz-btn-border-color);
        color: var(--vz-btn-border-color);
      }
    }
    .olap-row{
      margin: 0;
    }
  }
  .olap-table{
    max-width: 100%;
    overflow-x: scroll;
    position: relative;
    .season-view{
      td{
        min-width: 500px;
      }
    }
    table{
      width: 100%;
      thead th{
        position: relative;
        background-color: #fafafa;
        &.hovered{
            background-color: #f3f9f3;
        }
      }
      thead th:first-child {
        left: -1px;
        z-index: 1;
      }
      .dtr-control{
        //position: sticky;
        left: -1px;
        background-color: #fcfcfc;
        width: 250px;
        min-width: 250px;
        z-index: 1050;
        .rowTitle{
          position: relative;
          .rowTutorial{
            position: absolute;
            top: -10px;
            right: -10px;
            .btn-olap-success{
              padding: 0 !important;
              background-color: transparent;
              box-shadow: none !important;
              border: none;
              height: 14px;
              width: unset;
              font-size: unset;
              line-height: unset;
              svg{
                font-size: 14px !important;
                vertical-align: unset;
                color: #777;
              }
            }
          }
        }
      }
      td, th {
        min-width: 120px;
        padding: 15px;
      }
      .olap-input{
        height: 21px;
        width: 100%;
        margin: 0 !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: text;
        input{
          width: 100%;
          border-bottom: 1px solid #ccc;
          text-align: left !important;
        }
      }
      .btn-olap-modal{
        font-size: 12px;
        padding: 1px !important;
        width: 100%;
      }
      .table-month{
        span{
          position: sticky;
          left: 265px;
        }
      }
    }
    .olap-table-hover{
      tbody{
        tr:hover{
          td:first-child{
            background-color: #f3f9f3;
          }
          td:hover{
            background-color: #f3f9f3;
          }
        }
      }
    }
    .season-view{
      .olap-input{
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
        input{
          width: 100%;
        }
      }
    }
    .olap-open-button{
      .icon{
        transition: 0.3s all;
        display: inline-block;
        cursor: pointer;
      }
      &.active .icon{
        transform: rotate(180deg);
      }
    }
  }
  .profile-project-card.camp-profile-project-card{
    border: none;
    .card-body{
      border: 1px solid #e9ebec;
      padding-top: 40px !important;
    }
  }
  .card-header.camp-profile-card-header{
    background-color: #689f38;
    border-bottom: 1px solid #e9ebec;
    img{
      display: block;
      z-index: 1;
      position: relative;
      max-width: 70px;
      margin: 0 auto -49px;
      border-radius: 50%;
      border: 2px solid #e9ebec;
    }
    .organisationavatar{
      z-index: 1;
      position: relative;
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      margin: 0 auto -49px;
      border-radius: 50%;
      border: 2px solid #e9ebec;
      background-color: #ffffff;
      color: #689f38;
      font-size: 32px;
    }
  }
  .li-unstyled{
    padding-left: 10px !important;
    &:before{
      display: none;
    }
  }
  .li-unstyled2{
    padding-left: 20px !important;
  }
  .li-unstyled-30{
    padding-left: 30px !important;
  }
  .li-unstyled-60{
    padding-left: 60px !important;
  }
  .olap-sidebar-button{
    .icon{
      transition: 0.3s all;
      display: inline-block;
      transform: rotate(-90deg);
    }
    &.active .icon{
      transform: rotate(0deg);
    }
  }
  .droparea{
    border: 1px dashed #e9ebec;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    min-height: 50px;
    &:hover{
      border-color: #689f38;
    }
    .card{
      .card-body{
        padding: 12px;
      }
    }
  }
  .stroke-success{
    path{
      stroke: #689f38;
    }
  }
  .height-unset{
    height: unset !important;
    max-height: unset !important;
  }
  .olap-description-text{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .olap-table{
    .month-view{
      .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12{
        width: 100%;
      }
    }
    .ql-editor{
      height: 375px;
    }
  }
  .draggable{
    cursor: move;
  }
  .text-danger-hover{
    color: #878a99!important;
    &:hover{
      color: #f06548 !important;
    }
  }

  .text-success-hover{
    color: #878a99!important;
    &:hover{
      color: #689f38 !important;
    }
  }
  .pointer-event-none{
    pointer-events: none;
  }
  .white-space-normal{
    white-space: normal;
  }
  .weight-300{
    font-weight: 300;
  }

  .position-sticky-90{
    position: sticky;
    top: 90px;
  }

  .text-justify{
    text-align: justify;
  }
  .tooltipCp {
    position: relative;
    display: inline-block;
    margin-left: 4px;
    font-size: 14px;
    top: -6px;
    color: #ccc;
  }

  /* Tooltip text */
  .tooltipCp .tooltiptextCp {
    visibility: hidden;
    max-width: 500px;
    width: 500px;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 5px;
    font-size: 12px;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -250px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltipCp .tooltiptextCp::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltipCp:hover .tooltiptextCp {
    visibility: visible;
    opacity: 1;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }
  .olap-media-preview{
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-top: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
    .olap-media-inner{
      display: flex;
      min-width: 0;
      overflow: hidden;
      img{
        display: block;
        width: auto;
        height: 100%;
      }
    }
    .btn-remove{
      position: absolute;
      right: 0;
      top: 0;
      box-sizing: content-box;
      width: 1em;
      height: 1em;
      padding: 0;
      color: #f00;
      background: transparent;
      font-size: 20px;
      border: 0;
      border-radius: .25rem;
      opacity: .5;
      svg{
        vertical-align: top;
      }
      &:hover{
        opacity: 1;
      }
    }
  }

  .cp-tooltip{
    position: relative;
    display: inline-block;
    color: #ccc;
    .tooltiptext{
      visibility: hidden;
      max-width: 120px;
      width: 120px;
      background-color: #333;
      color: #fff;
      text-align: center;
      padding: 5px;
      font-size: 12px;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover{
      .tooltiptext{
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .new-object-toast{
    position: fixed;
    bottom: 40px;
    right: 80px;
    background-color: #689f38;
    box-shadow: unset;
    z-index: 101;
    cursor: unset;
    //box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 10px 36px -4px rgba(111, 232, 77, 0.3);
    .btn{
      color: #fff;
      opacity: 1;
      position: relative;
      display: inline-block;
      z-index: 111;
    }
  }


  @media (min-width: 1200px){
    .modal-xxl {
      --vz-modal-width: 1400px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px){
    .modal-xxl {
      --vz-modal-width: 1200px;
    }
  }
  .olap-table table .placeholder-background{
    background-color: #eeeeee;
  }
}

table {
  tr {
    th, td {
      vertical-align: top;

      &.no-min-width {
        min-width: 0 !important;
      }

      .table-cell-label {
        color: var(--vz-gray-600);
        cursor: default;
        font-size: 12px;
      }

      .delete-edit-geo-coordinates-item {
        vertical-align: top;
        cursor: pointer;
        color: var(--vz-gray-400);

        &:hover {
          color: var(--vz-danger);
        }
      }
    }
  }

  .no-hover-effect {
    tr, td {
      background-color: transparent !important;
    }
  }
}

.dropdown-menu {
  * {
    cursor: pointer;
  }
}

.collapse-able-row {
  display: none;

  td {
    padding-top: 0 !important;
  }

  &.open {
    display: table-row;
  }
}

.transparent-border {
  border-color: transparent !important;
}